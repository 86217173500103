import React from 'react';
import { authPages, dashboardMenu, demoPages, layoutMenu } from '../menu';
import DashboardHeader from '../pages/common/Headers/DashboardHeader';

const headers = [
  // { path: layoutMenu.pageLayout.subMenu.onlySubheader.path, element: null, exact: true },
  // { path: layoutMenu.pageLayout.subMenu.onlyContent.path, element: null, exact: true },
  // { path: layoutMenu.blank.path, element: null, exact: true },
  { path: authPages.login.path, element: null, exact: true },
  { path: authPages.signUp.path, element: null, exact: true },
  { path: authPages.page404.path, element: null, exact: true },
  { path: dashboardMenu.dashboard.path, element: <DashboardHeader />, exact: true },
  ...Object.keys(demoPages.pageLayout.subMenu).map(key => ({
    path: demoPages.pageLayout.subMenu[key].path, element: <DashboardHeader />, exact: true
  })),
  ...Object.keys(demoPages.pdvMenu.subMenu).map(key => ({
    path: demoPages.pdvMenu.subMenu[key].path, element: <DashboardHeader />, exact: true
  })),
  ...Object.keys(demoPages.store.subMenu).map(key => ({
    path: demoPages.store.subMenu[key].path, element: <DashboardHeader />, exact: true
  })),
  ...Object.keys(demoPages.command.subMenu).map(key => ({
    path: demoPages.command.subMenu[key].path, element: <DashboardHeader />, exact: true
  })),
  ...Object.keys(demoPages.reports.subMenu).map(key => ({
    path: demoPages.reports.subMenu[key].path, element: <DashboardHeader />, exact: true
  })),
  ...Object.keys(demoPages.delivery.subMenu).map(key => ({
    path: demoPages.delivery.subMenu[key].path, element: <DashboardHeader />, exact: true
  })),
  // { path: demoPages.notifications.path, element: <DashboardHeader />, exact: true },
  // { path: demoPages.contactUs.path, element: <DashboardHeader />, exact: true },
  { path: authPages.profile.path, element: <DashboardHeader />, exact: true },
  { path: demoPages.settings.path, element: <DashboardHeader />, exact: true },

  // { path: demoPages.reports.path, element: <DashboardHeader />, exact: true },
  // { path: demoPages.documentations.path, element: <DashboardHeader />, exact: true },
  /*{
    path: `*`,
    element: <DefaultHeader />,
  },*/
];

export default headers;
