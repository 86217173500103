import { useFormik } from 'formik';
import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { DefaultContext } from '../../../contexts/default';
import ClientDB from '../../../database/wrappers/client';
import ClientEstabDB from '../../../database/wrappers/clientEstab';
import masks from '../../../helpers/utils/masks';
import PreAlert from '../../../helpers/utils/preAlert';
import Button from '../../bootstrap/Button';
import Modal, { ModalBody, ModalHeader, ModalTitle } from '../../bootstrap/Modal';
import Spinner from '../../bootstrap/Spinner';
import FormGroup from '../../bootstrap/forms/FormGroup';
import Input from '../../bootstrap/forms/Input';
import InputGroup, { InputGroupText } from '../../bootstrap/forms/InputGroup';
import api from '../../../services/api';

const validate = (values) => {
  const errors = {};

  if (!values.name) {
    errors.name = 'Este campo é necessário';
  } else if (values.name.length < 3) {
    errors.name = 'O nome precisa ter 3 caracteres ou mais';
  }
  if (!values.email) {
    errors.email = 'Este campo é necessário.';
  } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
    errors.email = 'Email inválido.';
  }

  if (!values.cpf) {
    errors.cpf = 'Este campo é necessário';
  } else if (values.cpf.length < 14) {
    errors.cpf = 'Informe o cpf completo';
  }

  if (!values.phone) {
    errors.phone = 'Este campo é necessário';
  }

  if (!values.birthDate) {
    errors.birthDate = 'Este campo é necessário';
  }

  console.log(errors);

  return errors;

}

const ModalRegisterClients = ({ open, setIsOpen, setIsClose, editData }) => {
  const { establishment, onShowAlert } = useContext(DefaultContext)
  const [isLoading, setIsLoading] = useState(false);
  const [isAdd, setIsAdd] = useState(false);
  const duplicateError = () => {
    onShowAlert(PreAlert.error('Cliente já cadastrado'))
    setIsLoading(false)
  }

  const formik = useFormik({
    initialValues: {
      name: '',
      cpf: '',
      phone: '',
      email: '',
      birthDate: '',
      idCard: '',
      estabId: '',
      active: true,
    },
    validate,
    onSubmit: async values => {
      setIsLoading(true);
      const { name, cpf, email, phone, birthDate } = values;
      const data = {
        cpf: cpf?.replace(/\D/g, "") || "",
        phone: phone?.replace(/\D/g, "") || "",
        birthDate: birthDate,
        name: name,
        email: email,
        balance: 0,
      }
      const onSuccess = () => {
        onShowAlert(PreAlert.success('Registro efetuado com sucesso'))
      }
      const onError = () => {
        onShowAlert(PreAlert.error('Falhou ao registrar cliente'))
      }
      try {
        const dataCustomer = {
          estabId: establishment.id,
          clients: [data]
        }
        await api.post('routes/clients', dataCustomer)
        onSuccess();
      } catch (e) {
        onError();
      } finally {
        setIsLoading(false);
        setIsClose();
      }
    }
  },
  )

  useEffect(() => {
    if (!open) return formik.resetForm();
    if (editData) {
      const {
        email,
        name,
        active,
        phone,
        cpf,
        birthDate,
        id,
        idCard,
      } = editData;
      formik.setValues({
        name: name,
        active: active,
        cpf: masks.cpf(cpf),
        phone: masks.phone(phone),
        email: email,
        id: id,
        birthDate: birthDate,
        idCard: idCard
      });
    }
  }, [editData, open])

  const dadosClient = (client) => {
    formik.setValues({
      ...formik.values,
      name: client.name,
      phone: client.phone,
      birthDate: client.birthDate,
      sex: client.sex === 0 ? 'masc' : 'fem',
      email: client.email
    })
  }


  const clearFields = () => {
    formik.setValues({
      ...formik.values,
      name: '',
      phone: '',
      birthDate: '',
      idCard: '',
      email: ''
    })

  }
  useEffect(() => {
    const cpf = formik.values.cpf.replace(/\D/g, "");
    if (cpf === '') {
      clearFields()
    }
    setIsAdd(false)

    const getAutoComplete = async () => {
      const client = await new ClientDB().getByCPF(cpf);
      if (client) {
        const clientByEstab = await new ClientEstabDB(establishment.id).getById(client.id);
        if (clientByEstab) {
          duplicateError();
        }
        if (client) {
          dadosClient(client)
          setIsAdd(true)
        }
      }
    }
    if (cpf.length === 11 && !editData) {
      getAutoComplete();
    }
  }, [formik.values.cpf]);

  return (
    <Modal
      id={'modal-register-category'}
      titleId={'Cadastro de Categoria'}
      isOpen={open}
      setIsOpen={setIsOpen}
      isStaticBackdrop={true}
      isScrollable={false}
      isCentered={true}
      size="lg" // 'sm' || 'lg' || 'xl' 
      isAnimation={true}
      onSubmit={formik.handleSubmit}
    >
      <ModalHeader setIsOpen={setIsOpen}>
        <ModalTitle id="register-user">{editData ? 'Atualização de Cliente' : 'Cadastro de Cliente'}</ModalTitle>
      </ModalHeader>
      <ModalBody className='p-5'>
        <form noValidate onSubmit={formik.handleSubmit}>
          {/* Inputs */}
          <div className="row pb-2 d-flex">
            {/* cpf */}
            <FormGroup id="cpf" label="CPF/RNE" className='col-md-4 mb-4'>
              <Input
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={masks.cpf(formik.values.cpf)}
                isValid={formik.isValid}
                isTouched={formik.touched.cpf}
                invalidFeedback={formik.errors.cpf}
                validFeedback='Assim está bom!'
                placeholder='210.210.210-21'
              />
            </FormGroup>
            {/* Nome */}
            <FormGroup id="name" label="Nome" className='col-md-8 mb-4'>
              <Input
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.name}
                isValid={formik.isValid}
                isTouched={formik.touched.name}
                invalidFeedback={formik.errors.name}
                validFeedback='Assim está bom!'
                placeholder='Ex: João da Silva'
              />
            </FormGroup>

            {/* data nascimento */}
            <FormGroup id="birthDate" label="Data Nascimento" className='col-md-4 mb-4'>
              <Input
                type='text'
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={masks.date(formik.values.birthDate)}
                isValid={formik.isValid}
                isTouched={formik.touched.birthDate}
                invalidFeedback={formik.errors.birthDate}
                validFeedback='Assim está bom!'
                placeholder='DD/MM/YY'
              />
            </FormGroup>
            {/* phone */}
            <FormGroup id="phone" label="Telefone" className='col-md-4 mb-4'>
              <Input
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={masks.phone(formik.values.phone)}
                isValid={formik.isValid}
                isTouched={formik.touched.phone}
                invalidFeedback={formik.errors.phone}
                validFeedback='Assim está bom!'
                placeholder='(11) 97777-1111'
              />
            </FormGroup>
            {/* id cartao */}
            <FormGroup id="idCard" label="NFC" className='col-md-4 mb-4'>
              <Input
                disabled={true}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.idCard}
                isValid={formik.isValid}
                isTouched={formik.touched.idCard}
                invalidFeedback={formik.errors.idCard}
                validFeedback='Assim está bom!'
                placeholder='EX3M1P23'
              />
            </FormGroup>

            {/* Email */}
            <FormGroup id="email" label="Email" className='col-md-12 mb-4'>
              <InputGroup>
                <InputGroupText id="inputGroupPrepend">
                  @
                </InputGroupText>
                <Input
                  id="email"
                  ariaDescribedby='inputGroupPrepend'
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.email}
                  isValid={formik.isValid}
                  isTouched={formik.touched.email}
                  invalidFeedback={formik.errors.email}
                  validFeedback='Assim está bom!'
                  placeholder="joaodasilva@email.com"
                />
              </InputGroup>
            </FormGroup>


            {/* {(accessLevel === 3) && (
              <FormGroup id="estabId" label="Estabelecimento" className="col-md-6">
                <Select
                  value={formik.values.estabId}
                  list={estabOptions}
                  onBlur={formik.handleBlur}
                  onChange={onChangeEstablishment}
                  isValid={formik.isValid}
                  invalidFeedback={formik.errors.estabId}
                />
              </FormGroup>
            )} */}
          </div>

          {/* Buttons */}
          <div className="row pt-4">
            <div className='d-flex justify-content-end gap-2'>
              <Button
                color="danger"
                icon="cancel"
                rounded={1}
                onClick={setIsClose}
                shadow="sm"
                hoverShadow="sm"
                size="sm"
              >
                Cancelar
              </Button>
              {isLoading ? (
                <Button
                  color="success"
                  rounded={1}
                  hoverShadow="sm"
                  shadow="sm"
                  size="sm"
                >
                  <Spinner
                    color="light"
                    inButton
                    isGrow
                    isSmall
                    size={10}
                    tag="span"
                  >
                    Carregando...
                  </Spinner>
                  Carregando...
                </Button>
              ) : (
                <Button
                  isDisable={!formik.isValid || isLoading}
                  type='submit'
                  color='success'
                  icon="check"
                  rounded={1}
                  hoverShadow="sm"
                  shadow="sm"
                  size="sm"
                >
                  {editData ? 'Adicionar' : 'Confirmar'}
                </Button>
              )}
            </div>
          </div>
        </form>
      </ModalBody>
    </Modal>
  )
}

export default ModalRegisterClients;