import { StringMap } from "i18next";
import ComplementSold from "../../../database/entities/sales/complementSold";
import ProductSold from "../../../database/entities/sales/productSolds.entity";
import Sale from "../../../database/entities/sales/sale.entity";
import { EAT_OR_TAKE } from "../../../types/payment";
import { DEVICE } from "../../../types/device";


interface ProductByStore {
  id: string,
  storeId: string,
  qtd: number,
  price: number,
  qtdPos: number,
  qtdTotem: number,
  total: number,
}

interface ComplementsByStore {
  id: string,
  storeId: string,
  qtd: number,
  price: number
}
class SalesProcess {
  private productsSold: ProductSold[] = [];
  private complementsSold: ComplementSold[] = [];
  private ProductByStore: ProductByStore[] = [];
  private ComplementsByStore: ComplementsByStore[] = [];
  private eatHere = 0;
  private toTake = 0;

  constructor(datas: Sale[]) {
    datas.forEach((sale: Sale) => {
      if (sale.reversed) return;
      sale.products.forEach((product: any) => {
        product.totemId = sale.totemId as any;
        const { storeId } = sale;
        product.complements.forEach((comp: any) => {
          comp.qtd = comp.qtd * product.qtd;
          const {id: complementId, qtd,price} = comp;
          const existingComplements = this.ComplementsByStore.find(item => item.id === complementId && item.storeId === storeId);
          if(existingComplements){
            existingComplements.qtd += qtd;
          } else {
            this.ComplementsByStore.push({
              storeId: storeId,
              id: complementId,
              qtd: qtd,
              price: price,
            })
          }
        });
        const { id: productId, qtd,price } = product; 
        const existingProduct = this.ProductByStore.find(item => item.id === productId && item.storeId === storeId);
        if (existingProduct) {
          const key = DEVICE.POS ? 'qtdPos' : 'qtdTotem';
          existingProduct.qtd += qtd;
          existingProduct[key] += qtd;
          existingProduct.total += price * qtd;
        } else {
          this.ProductByStore.push({
            storeId: storeId,
            id: productId,
            qtd: qtd,
            price: price,
            total: price * qtd,
            qtdPos: sale.device === DEVICE.POS ? qtd : 0,  
            qtdTotem: sale.device === DEVICE.TOTEN ? qtd : 0,

          });
        }

        this.complementsSold.push(...product.complements);
      });

      this.productsSold.push(...sale.products);

      if (sale.earOrTake === EAT_OR_TAKE.EAT_HERE) this.eatHere++;
      else if (sale.earOrTake === EAT_OR_TAKE.TO_TAKE) this.toTake++;
    });
  }

  getProductByStore() {
    return this.ProductByStore;
  }

  getComplementByStore() {
    return this.ComplementsByStore;
  }

  getProductsSold(): ProductSold[] {
    return this.productsSold;
  }

  getComplementsSold(): ComplementSold[] {
    return this.complementsSold;
  }

  getEatHere() {
    return this.eatHere;
  }

  getToTake() {
    return this.toTake;
  }
}

export default SalesProcess;
