import React, { useEffect, useState } from 'react';

import Page from '../../../../layout/Page/Page';
import Card, { CardBody } from '../../../../components/bootstrap/Card';
import useTotalByDay from '../../../../hooks/useTotalByDay';
import Chart from '../../../../components/extras/Chart';
import dateFormat from 'dateformat';
import Money from '../../../../helpers/utils/money/money';

const Faturamento = ({ mergedByDay }) => {

  const [columnBasic, setColumnBasic] = useState({
    series: [
      {
        data: mergedByDay.map((item) => Money.centsToCoin(item.total)),
        name: 'Faturamento',
      },
      {
        data: mergedByDay.map((item) => Money.centsToCoin(Math.round(item.total / item.count))),
        name: 'Ticket médio',
      },
    ],
    options: {
      chart: {
        type: 'bar',
        height: 350,
        toolbar: {
          show: true,
        }
      },
      responsive: [
        {
          breakpoint: 480,
          options: {
            legend: {
              position: 'bottom',
              offsetX: -10,
              offsetY: 0,
            },
          },
        },
      ],

      plotOptions: {
        bar: {
          horizontal: false,
          columnWidth: '55%',
          endingShape: 'rounded',
        },
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        show: true,
        width: 2,
        colors: ['transparent'],
      },
      xaxis: {
        categories: mergedByDay.map((item) => item?.date),

      },
      yaxis: {
        title: {
          text: 'Gráfico por dia',
        },
      },
      fill: {
        opacity: 1,
      },
      tooltip: {
        y: {
          formatter(val) {
            return Money.centsToMaskMoney(val * 100);
          },
        },
      },
    },
  });

  useEffect(() => {
    setColumnBasic({
      series: [
        {
          data: mergedByDay.map((item) => Money.centsToCoin(item?.total)),
          name: 'Faturamento'
        },
        {
          data: mergedByDay.map((item) => Money.centsToCoin(Math.round(item.total / item.count))),
          name: 'Ticket médio'
        },

      ],
      options: {
        chart: {
          type: 'bar',
          height: 350,
        },
        plotOptions: {
          bar: {
            horizontal: false,
            barWidth: '100%',
            // @ts-ignore
            endingShape: 'rounded',
            dataLabels: {
              orientation: 'vertical',
              position: 'top',
              horizontalAlign: 'center',
            }
          },
        },
        dataLabels: {
          enabled: true,
          offsetY: 5,
          style: {
            fontSize: '12px',
            colors: ['#000000']
          }

        },
        stroke: {
          show: true,
          width: 2,
          colors: ['transparent'],
        },
        xaxis: {
          categories: mergedByDay.map((item) => item?.date)
          ,
        },
        yaxis: {
          title: {
            text: 'Gráfico por dia'
          }
        },
        fill: {
          opacity: 1,
        },
        tooltip: {
          y: {
            formatter(val) {
              return Money.centsToMaskMoney(val * 100);
            },
          },
        },
      },
    })

  }, [mergedByDay]);
  return (
    <>
      <Page container='fluid'>
        <Card>
          <CardBody className='table-responsive p-5'>
            <Chart
              series={columnBasic.series}
              options={columnBasic.options}
              type='bar'
              height={350}
            />
          </CardBody>
        </Card>
      </Page>
    </>
  );
};

export default Faturamento;
