import { useContext, useEffect, useState } from "react";
import { DefaultContext } from "../../../../contexts/default";
import ProductDB from "../../../../database/wrappers/product";
import { orderBy } from "firebase/firestore";
import StoreProductDB from "../../../../database/wrappers/storeProduct";
import Button from "../../../bootstrap/Button";
import Popovers from "../../../bootstrap/Popovers";
import Logo from "../../../Logo";
import Modal, { ModalBody, ModalHeader, ModalTitle, ModalFooter } from "../../../bootstrap/Modal";
import TableCustom from "../../../MyCustom/TableCustom";
import api from "../../../../services/api";
import Spinner from "../../../bootstrap/Spinner";
import Money from '../../../../helpers/utils/money/money';
import SubCategoryDB from "../../../../database/wrappers/subCategory";
import PreAlert from "../../../../helpers/utils/preAlert";



const ModalProducts = ({ open, handleOpen, handleNext, handleBack, categorySelected, setProductSelected }) => {
    const { establishment, store, onShowAlert, storeInfo } = useContext(DefaultContext);

    const [products, setProducts] = useState([])
    const [subCategories, setSubCategories] = useState([])
    const [storeProductsDicionary, setStoreProductsDicionary] = useState([])
    const [productsRender, setProductsRender] = useState([])
    const [loading, setloading] = useState(false);

    useEffect(() => {
        if (establishment && categorySelected) {
            new SubCategoryDB(establishment.id)
                .getAll()
                .then((data) => {
                    setSubCategories(data.filter((sub) => categorySelected?.subCategories?.includes(sub.id)));
                })
        }

    }, [establishment, categorySelected])

    const processSubCategories = (subCats, productsData) => {
        const productsMounted = [];
        subCats?.forEach(subCat => {
            subCat.products?.forEach((productId) => {
                const prodFind = productsData.find(prod => prod.id === productId);
                if (prodFind) {
                    productsMounted.push(prodFind);
                }
            });
            subCat.subCategories?.forEach((categoryId) => {
                const subFind = subCats?.find(cat => cat.id === categoryId);
                if (subFind) {
                    subFind?.products?.forEach((productId) => {
                        const prodFind = productsData?.find(prod => prod.id === productId);
                        if (prodFind) {
                            productsMounted.push(prodFind);
                        }
                    });
                }
            })
            if (subCat.subCategories && subCat.subCategories.length > 0) {
                processSubCategories(subCat.subCategories);
            }
        });

        return productsMounted;
    };

    useEffect(() => {
        if (establishment && categorySelected)
            new ProductDB(establishment.id)
                .getAll(orderBy('name', 'asc'))
                .then((data) => {
                    const productsInSubCat = processSubCategories(subCategories, data);
                    const productsInCategory = data.filter((p) => categorySelected?.products?.includes(p.id))
                    setProducts(prevProducts => {
                        const updatedProducts = productsInCategory.concat(productsInSubCat);
                        return updatedProducts.filter((product, index, self) =>
                            index === self.findIndex(p => p.id === product.id)
                        );
                    });

                })
    }, [establishment, categorySelected, subCategories])

    useEffect(() => {
        if (!establishment || !store) return;
        const onSubscribe = new StoreProductDB(establishment.id, store.id).on(datas => {
            const dicionary = {};
            datas.forEach(data => dicionary[data.id] = data)
            setStoreProductsDicionary(dicionary)
        });
        return onSubscribe;
    }, [establishment, store])

    useEffect(() => {
        if (products !== null && storeProductsDicionary !== null) {
            const productsRender = products.map(product => {
                const storeProd = storeProductsDicionary[product.id];
                return {
                    id: product.id,
                    name: product.name,
                    delivery_code: product.delivery_code,
                    price: storeProd?.delivery_price || storeProd?.price || product.price,
                    ifoodId: storeProd?.ifoodId,
                    complements: product.complements,
                    statusIfood: storeProd?.statusIfood,
                    image_url: product.image_url
                }
            })
            setProductsRender(productsRender.filter((p) => !p.statusIfood));
        }
    }, [products, storeProductsDicionary])

    const HandleStatus = async (row) => {
        setloading(true);
        if (!row.ifoodId) {
            await api.post('routesIfood/registerProduct',
                { storeInfo, productInfo: { name: row.name, id: row.id } })
        } else if (row.ifoodId) {
            await api.post('routesIfood/deleteProduct',
                { storeInfo, productInfo: { ifoodId: row.ifoodId, id: row.id } })
        }
        setloading(false)
    }

    const registerItem = async (row) => {
        if (!row.ifoodId) return onShowAlert(PreAlert.error("Ative o item, antes de registra-lo."))

        setloading(true);
        if (!row.statusIfood)
            await api.post('routesIfood/registerItem', {
                storeInfo,
                productId: row.id,
                categoryId: categorySelected.id
            })
                .then()
                .catch(err => console.error(err))
                .finally(() => setloading(false))
        else
            onShowAlert(PreAlert.error('Item já registrado no IFood.'))
    }

    const columns = [
        {
            label: 'Imagem',
            field: 'image_url',
            format: row => (
                <>
                    {row.image_url ? (
                        <img src={row.image_url} alt="Imagem" width={54} height={54} />
                    ) : (
                        <Logo width={54} black />
                    )}
                </>
            )
        },
        {
            label: 'Nome',
            field: 'name',
        },
        {
            label: 'Delivery-code',
            field: 'delivery_code',
        },
        {
            label: 'Preco',
            field: 'price',
            format: row => (
                <span>{Money.centsToMaskMoney(row.price)}</span>
            ),
            formatExport: value => Money.centsToCoin(value)
        },
        {
            label: 'Ifood Status',
            field: 'ifoodId',
            format: row => {
                return (
                    <>
                        {!loading ?
                            <Button
                                isLink
                                color={row.ifoodId ? 'success' : 'danger'}
                                icon='Circle'
                                onClick={() => HandleStatus(row)}
                                className='text-nowrap'>
                                {row.ifoodId ? 'Ativo' : 'Inativo'}
                            </Button>
                            :
                            <Spinner />
                        }
                    </>
                )
            }
        },
        // {
        //     label: 'Rappi Status',
        //     field: 'ifoodId',
        //     format: row => (
        //         <Button
        //             isLink
        //             color={row.ifoodId ? 'success' : 'danger'}
        //             icon='Circle'
        //             onClick={() => { }}
        //             className='text-nowrap'>
        //             {row.ifoodId ? 'Ativo' : 'Inativo'}
        //         </Button>
        //     )
        // }
        {
            label: 'Ações',
            format: row => (
                <>
                    {!loading ?
                        <>
                            <Popovers
                                trigger="hover"
                                title="Registrar Item"
                            >
                                <Button
                                    isLink
                                    color="dark"
                                    icon='arrow-bar-up'
                                    onClick={() => registerItem(row)}
                                >
                                </Button>
                            </Popovers>
                            <Popovers
                                trigger="hover"
                                title="Registrar Categoria de Complemento"
                            >
                                <Button
                                    isLink
                                    color="dark"
                                    icon='Pen'
                                    onClick={() => {
                                        setProductSelected(row)
                                        handleOpen(false)
                                        handleNext(true)
                                    }}
                                >
                                </Button>
                            </Popovers>
                        </>
                        :
                        <Spinner />
                    }
                </>
            )
        }
    ]

    return (
        <>
            <Modal
                id={'modal-products'}
                titleId={'Cadastro Products'}
                isOpen={open}
                setIsOpen={handleOpen}
                isStaticBackdrop={true}
                isScrollable={false}
                isCentered={true}
                size="xl" // 'sm' || 'lg' || 'xl' 
                isAnimation={true}
                onSubmit={() => { }}
            >
                <ModalHeader
                    setIsOpen={() => handleOpen(false)}
                >
                    <ModalTitle id="register-item">Cadastro de Produtos</ModalTitle>
                </ModalHeader>
                <ModalBody className='p-3'>
                    <TableCustom
                        columns={columns}
                        rows={productsRender}
                        fileName={'Categoria-de-Gerenciamento'}
                    />
                </ModalBody>

                <ModalFooter>
                    <Button
                        color='info'
                        rounded={1}
                        hoverShadow="sm"
                        shadow="sm"
                        size="sm"
                        onClick={handleBack}
                    >
                        Voltar
                    </Button>
                </ModalFooter>




            </Modal>
        </>
    )
}

export default ModalProducts