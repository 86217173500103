import React, { useCallback, useMemo, useContext, useEffect, useState } from 'react';
import PageWrapper from '../../../../layout/PageWrapper/PageWrapper';
import SubHeader, { SubHeaderLeft, SubHeaderRight } from '../../../../layout/SubHeader/SubHeader';
import Page from '../../../../layout/Page/Page';
import Breadcrumb from '../../../../components/bootstrap/Breadcrumb';
import { demoPages } from '../../../../menu';
import { DefaultContext } from '../../../../contexts/default';
import Card, { CardBody } from '../../../../components/bootstrap/Card';
import Button from '../../../../components/bootstrap/Button';
import TableCustom from '../../../../components/MyCustom/TableCustom';
import SelectEstablishment from '../../../../components/MyCustom/SelectEstablishment';
import SelectStore from '../../../../components/MyCustom/SelectStore';
import ModalRegisterStoreDefects from '../../../../components/modals/StoreDefects/ModalRegister';
import DefectDB from '../../../../database/wrappers/defect';
import useDarkMode from '../../../../hooks/useDarkMode';
import ProductDB from '../../../../database/wrappers/product';
import Money from '../../../../helpers/utils/money/money';
import { orderBy } from 'firebase/firestore';
import { ACCESS_LEVEL } from '../../../../types/roles';
import { de } from 'date-fns/locale';
import logo from '../../../../assets/img/logo_uget_black.png'

const Defect = () => {
  const { darkModeStatus } = useDarkMode();
  const [openModalRegister, setOpenModalRegister] = useState(false);
  const { establishment, store, onShowAlert, accessLevel } = useContext(DefaultContext)
  const [defects, setDefects] = useState(null)
  const [defectsRender, setDefectsRender] = useState([])
  const [storeProductsDicionary, setstoreProductsDicionary] = useState(null)
  const [defectSelected, setDefectSelected] = useState({});

  useEffect(() => {
    if (!establishment) return;
    const onSubscribe = new DefectDB(establishment.id).on(datas => {
      setDefects(datas)
    })
    return onSubscribe
  }, [establishment, store])


  const row = [
    {
      id: 'testeastasdsd212',
      name: 'Teste',
      description: 'Teste',
      qtd: 10,
      unit: 'kg',
      type: 'Sobra',
      storeId: 'apMheC5iSkaImLEhG3FT',
      productId: '74ghth6WMVIT3Ag54JpD'
    }
  ]

  useEffect(() => {
    if (!establishment || !store) return;
    const onSubscribe = new ProductDB(establishment.id).on(datas => {
      const dicionary = {};
      datas.forEach(data => dicionary[data.id] = data)
      setstoreProductsDicionary(dicionary)
    });
    return onSubscribe;
  }, [establishment, store])


  useEffect(() => {
    if (storeProductsDicionary !== null && defects !== null) {
      const defectsRender = defects
        .filter(defect => defect.storeId === store.id)
        .map(defect => {
          const storeProd = storeProductsDicionary[defect.productId];
          storeProd.defect = defect;
          return {
            ...storeProd,
          };
        });

      setDefectsRender(defectsRender);
    }
  }, [defects, storeProductsDicionary]);


  const handleOpenModalRegister = useCallback(() => {
    setDefectSelected(null)
    setOpenModalRegister(true);
  }, [])

  const handleOpenModalEdit = useCallback((item) => {
    setDefectSelected(item)
    setOpenModalRegister(true);
  }, [])

  const handleCloseModalRegister = useCallback(() => {
    setOpenModalRegister(false);
  }, [])



  const columns = useMemo(() => ([
    {
      label: 'Imagem',
      field: 'image_url',
      format: row => (
        <>
          {row.image_url ? (
            <img src={row.image_url} alt="Imagem" width={54} height={54} />
          ) : (
            <img src={logo} alt="Imagem" width={60} height='auto' />
          )}
        </>
      ),
      noExport: true
    },
    {
      label: 'Nome',
      field: 'name',
    },
    {
      label: 'Unidade',
      field: 'unit',
      
    },
    {
      label: 'Quantidade',
      field: '{row}',
      format: row => row.defect.qtd,
      formatExport: row => row.defect.qtd

    },
    {
      label: 'Tipo',
      field: '{row}',
      format: row => row.defect.type,
      formatExport: row => row.defect.type
      
    },
    {
      label: 'Ações',
      field: 'actions',
      format: row => (
        <div className='w-50 d-flex justify-content-between'>
          <Button
            color={darkModeStatus ? 'light' : 'dark'}
            icon="edit"
            hoverShadow="md"
            size="sm"
            text=''
            onClick={() => handleOpenModalEdit(row)}
          />
        </div>
      ),
      noExport: true
    },
  ]), [handleOpenModalEdit])

  console.log(defectsRender, 'Aquii de novo')

  return (
    <>
      <PageWrapper title={demoPages.store.subMenu.defect.text}>
        <SubHeader>
          <SubHeaderLeft>
            <Breadcrumb
              list={[
                { title: 'Loja', to: '/store' },
                { title: 'Avaria ou Sobra', to: '/store/defect' },
              ]}
            />
          </SubHeaderLeft>
          <SubHeaderRight>
            {accessLevel >= ACCESS_LEVEL.MANAGER &&
              <Button
                color='primary'
                icon='plus'
                shadow="sm"
                hoverShadow="sm"
                onClick={handleOpenModalRegister}
              >
                Cadastrar
              </Button>}
          </SubHeaderRight>
        </SubHeader>
        <Page container='fluid'>
          <Card>
            <CardBody className='table-responsive p-5'>
              <div className='row d-flex align-items-center mt-4 mx-1' >
                <SelectEstablishment />
                <SelectStore />
              </div>
              {defectsRender && defectsRender.length > 0 && (

                <TableCustom columns={columns} rows={defectsRender.length > 0 ? defectsRender : []} fileName={'Avaria-ou-sobra'} />
              )}
            </CardBody>
          </Card>
        </Page>
      </PageWrapper>

      <ModalRegisterStoreDefects
        open={openModalRegister}
        setIsOpen={setOpenModalRegister}
        setIsClose={handleCloseModalRegister}
        defectSelected={defectSelected}
      />
    </>
  );
};

export default Defect;
