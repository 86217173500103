import React, { useState, useContext, useEffect, memo } from 'react';
import { useFormik } from 'formik';
import Button from '../../bootstrap/Button';
import FormGroup from '../../bootstrap/forms/FormGroup';
import Input from '../../bootstrap/forms/Input';
import Textarea from '../../bootstrap/forms/Textarea';
import Modal, { ModalBody, ModalHeader, ModalTitle } from '../../bootstrap/Modal';
import Spinner from '../../bootstrap/Spinner';
import Select from '../../bootstrap/forms/Select';
import { DefaultContext } from '../../../contexts/default';
import PreAlert from '../../../helpers/utils/preAlert';
import DefectDB from '../../../database/wrappers/defect';
import ProductDB from '../../../database/wrappers/product';
import StockProductDB from '../../../database/wrappers/stockProduct';
const validate = (values) => {
  const errors = {};

  // const value = Number(unmask(values.price))
  // if (!values.price || isNaN(value) || value === 0) {
  //   errors.price = 'Informe um número válido';
  // }

  return errors;
}

const _selectUnit = [
  { value: 'KG', text: 'KG' },
  { value: 'UN', text: 'UN' },
  { value: 'L', text: 'L' },
  { value: 'ML', text: 'ML' },
  { value: 'G', text: 'G' },
  { value: 'PC', text: 'PC' },
]

const _selectType = [
  { value: 'Quebrado', text: 'Quebrado' },
  { value: 'Vencido', text: 'Vencido' },
  { value: 'Avaria', text: 'Avaria' },
  { value: 'Sobra', text: 'Sobra' },
  { value: 'Outros', text: 'Outros' },
]

const ModalRegisterStoreDefects = ({ open, setIsOpen, setIsClose, defectSelected }) => {

  const { establishment, store, onShowAlert, user } = useContext(DefaultContext)
  const [products, setProducts] = useState([]);
  const [productSelected, setProductSelected] = useState(defectSelected?.id);
  const [searchValue, setSearchValue] = useState('');
  const [filteredProducts, setFilteredProducts] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isResultVisible, setIsResultVisible] = useState(true);
  const [showResults, setShowResults] = useState(false);
  const [selectedResultIndex, setSelectedResultIndex] = useState(0);
  const [productName, setProductName] = useState('');

  // console.log('defectSelected', defectSelected);

  useEffect(() => {
    if (establishment) {
      async function getProducts() {
        const allProducts = await new ProductDB(establishment.id).getAll();
        const filteredProducts = allProducts.filter(product => product.active === true);
        filteredProducts.sort((a, b) => a.name.localeCompare(b.name));
        setProducts(filteredProducts);
      }
      getProducts();
    }
  }, [establishment]);


  const handleSearchChange = (event) => {
    const value = event.target.value;
    setSearchValue(value);
    if (value.length >= 3) {
      const filteredProducts = products.filter((product) => {
        return product.name.toLowerCase().includes(value.toLowerCase());
      });
      setFilteredProducts(filteredProducts);
      setShowResults(true);
      setIsResultVisible(true);
    } else {
      setFilteredProducts([]);
      setShowResults(false);
      setIsResultVisible(false);
    }
  };



  useEffect(() => {
    if (!open) return formik.resetForm() && setIsLoading(false) && setProductName('');
    if (defectSelected) {
      handleSearchChange({ target: { value: defectSelected.name } });
      setProductName(defectSelected.name || '');
      const { productId, unit, qtd, description, type } = defectSelected.defect;
      formik.setFieldValue('productId', productId || '');
      formik.setFieldValue('unit', unit || '');
      formik.setFieldValue('qtd', qtd || '');
      formik.setFieldValue('description', description || '');
      formik.setFieldValue('type', type || '');
    }
  }, [defectSelected, open])



  const formik = useFormik({
    initialValues: {
      productId: '',
      unit: '',
      qtd: null || 0,
      description: '',
      type: '',
    },
    validate,
    onSubmit: values => {
      const { productId, unit, qtd, description, type } = values;

      const data = {
        productId: productId,
        unit: unit,
        qtd: qtd,
        description: description,
        type: type,
        storeId: store.id,
      }

      const stockData = {
        productId: productId,
        qtd: qtd,
      }

      const onSuccess = () => {
        onShowAlert(PreAlert.success('Registro cadastrado com sucesso'))
        setIsClose();
      }
      const onSuccessUpdate = () => {
        onShowAlert(PreAlert.success('Registro atualizado com sucesso'))
        setIsClose();
      }
      const onError = (error) => {
        console.error(error);
        onShowAlert(PreAlert.error('Falhou ao cadastrar o registro'))
      }

      // console.log('data', data);


      setIsLoading(true)
      if (defectSelected && defectSelected.defect) {
        new DefectDB(establishment.id)
          .update(defectSelected.defect.id, data)
          .then(onSuccess)
          .catch(onError)
          .finally(() => setIsLoading(false))
      }
      else {
        new StockProductDB(establishment.id, store.id)
          .get(productId)
          .then((stock) => {
            const newQtd = stock.qtd - qtd;
            new StockProductDB(establishment.id, store.id)
              .update(productId, { qtd: newQtd })
              .then(() => {
                new DefectDB(establishment.id)
                  .create(data)
                  .then(onSuccess)
                  .catch(onError)
                  .finally(() => setIsLoading(false))
              }
              )
          }
          )
      }

    },
  })

  useEffect(() => {
    if (productSelected) {
      formik.setFieldValue('productId', productSelected?.id || '');
    }
  }, [productSelected]);



  return (
    <Modal
      id={'modal-register-totem'}
      titleId={'Cadastro de Totem'}
      isOpen={open}
      setIsOpen={setIsOpen}
      isStaticBackdrop={true}
      isScrollable={false}
      isCentered={true}
      size="lg" // 'sm' || 'lg' || 'xl' 
      isAnimation={true}
      onSubmit={formik.handleSubmit}
    >
      <ModalHeader setIsOpen={setIsOpen}>
        <ModalTitle id="register-totem">{defectSelected ? 'Atualização de Avaria ou Sobra' : 'Cadastro de Avaria ou Sobra'}</ModalTitle>
      </ModalHeader>
      <ModalBody className='p-5'>
        <form noValidate onSubmit={formik.handleSubmit}>
          {/* Inputs */}
          <div className="row pb-2 d-flex">
            {/* productId */}
            <FormGroup id='productId' label='Produto' className='col-md-5 mb-4'>
              <div>
                <Input
                  type="text"
                  value={searchValue}
                  onChange={handleSearchChange}
                  placeholder="Search Product"
                />
                {isResultVisible && (
                  filteredProducts.length > 0 ? (
                    filteredProducts.slice(0, 5).map((product, index) => (
                      <div
                        className='m-2'
                        key={product.id}
                        onClick={() => {
                          setProductSelected(product);
                          setIsResultVisible(false);
                          setSearchValue(product.name);
                        }}
                        style={{
                          backgroundColor: index === selectedResultIndex ? 'lightgray' : 'transparent',
                          cursor: 'pointer'
                        }}
                      >
                        <span>{product.name}</span>
                        {/* Render other product details */}
                      </div>
                    ))
                  ) : (
                    <div>No results</div>
                  )
                )}
              </div>
            </FormGroup>

            {/* type */}
            <FormGroup
              id='type' label='Tipo' className='col-md-3 mb-4'>
              <Select
                placeholder=' '
                list={_selectType}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.type}
                isValid={formik.isValid}
                isTouched={formik.touched.type}
                invalidFeedback={formik.errors.type}
              />
            </FormGroup>

            {/* unit */}
            <FormGroup id='unit' label='Unidade' className='col-md-2 mb-4'>
              <Select
                placeholder=' '
                list={_selectUnit}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.unit}
                isValid={formik.isValid}
                isTouched={formik.touched.unit}
                invalidFeedback={formik.errors.unit}
              />
            </FormGroup>

            {/* qtd */}
            <FormGroup
              id='qtd' label='Quantidade' className='col-md-2 mb-4'
            >
              <Input
                disabled={defectSelected}
                type="number"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.qtd}
                isValid={formik.isValid}
                isTouched={formik.touched.qtd}
                invalidFeedback={formik.errors.qtd}
                validFeedback='Assim está bom!'
                placeholder='5'
              />
            </FormGroup>

            {/* description */}
            <FormGroup
              id='description' label='Descrição' className='col-md-12 mb-4'
            >
              <Textarea
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.description}
                isValid={formik.isValid}
                isTouched={formik.touched.description}
                invalidFeedback={formik.errors.description}
                validFeedback='Assim está bom!'
                placeholder='Descreva o motivo da avaria ou sobra'
                rows={4}
              />
            </FormGroup>
          </div>
          {/* Buttons */}
          <div className="row pt-4">         
              <div className='d-flex justify-content-end gap-2'>
                <Button
                  color="danger"
                  icon="cancel"
                  rounded={1}
                  onClick={setIsClose}
                  shadow="sm"
                  hoverShadow="sm"
                  size="sm"
                >
                  Cancelar
                </Button>
                {isLoading ? (
                  <Button
                    color="success"
                    rounded={1}
                    hoverShadow="sm"
                    shadow="sm"
                    size="sm"
                  >
                    <Spinner
                      color="light"
                      inButton
                      isGrow
                      isSmall
                      size={10}
                      tag="span"
                    >
                      Carregando...
                    </Spinner>
                    Carregando...
                  </Button>
                ) : (
                  <Button
                    isDisable={!formik.isValid && !!formik.submitCount}
                    type='submit'
                    color='success'
                    icon="check"
                    rounded={1}
                    hoverShadow="sm"
                    shadow="sm"
                    size="sm"
                  >
                    Confirmar
                  </Button>
                )}
              </div>
            </div>
        </form>
      </ModalBody>
    </Modal>
  )
}

export default memo(ModalRegisterStoreDefects);