import React, { useState, useContext, useEffect, useMemo, useCallback } from 'react';
import { useFormik } from 'formik';
import Button from '../../bootstrap/Button';
import FormGroup from '../../bootstrap/forms/FormGroup';
import Input from '../../bootstrap/forms/Input';
import Checks, { ChecksGroup } from '../../bootstrap/forms/Checks';
import Label from '../../bootstrap/forms/Label';
import Modal, { ModalBody, ModalHeader, ModalTitle } from '../../bootstrap/Modal';
import Spinner from '../../bootstrap/Spinner';
import showNotification from '../../extras/showNotification';
import { Options } from '../../bootstrap/Option';
import Select from '../../bootstrap/forms/Select';
import InputGroup, { InputGroupText } from '../../bootstrap/forms/InputGroup';
import SelectEstablishment from '../../MyCustom/SelectEstablishment';
import { ACCESS_LEVEL, convertRolesPTBR, convertTypesCashierPTBR, ROLE, TYPE_CASHIER } from '../../../types/roles';
import { DefaultContext } from '../../../contexts/default';

import api from '../../../services/api';
import ListCheckbox from '../../MyCustom/ListCheckbox';
import StoreDB from '../../../database/wrappers/store';
import { orderBy } from 'firebase/firestore';
import PreAlert from '../../../helpers/utils/preAlert';
import StoreSettingDB from '../../../database/wrappers/storeSettings';
import MenuDB from '../../../database/wrappers/menu';

const validate = (values) => {
  const errors = {};

  if (!values.name) {
    errors.name = 'Este campo é necessário';
  } else if (values.name.length < 3) {
    errors.name = 'O nome precisa ter 3 caracteres ou mais';
  }

  if (!values.active) {
    errors.active = 'Você precisa escolher o status da categoria';
  }

  if (!values.password) {
    errors.password = 'Este campo é necessário';
  } else if (values.password.length < 6) {
    errors.password = 'A senha precisa ter 6 caracteres';
  }

  if (!values.role) {
    errors.role = 'Este campo é necessário';
  }

  if (!values.email) {
    errors.email = 'Este campo é necessário.';
  } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
    errors.email = 'Email inválido.';
  }

  if (values.role !== ROLE.ADMIN && !values.estabId) {
    errors.estabId = 'É preciso selecionar o estabelecimento';
  }

  if (values.role === ROLE.CASHIER && !values.typeCashier) {
    errors.typeCashier = 'O usuário Caixa deve ter um tipo de caixa';
  }

  return errors;

}



const ModalRegisterUsers = ({ open, setIsOpen, setIsClose, editData, loadUsers }) => {
  const { user, accessLevel, establishments, onShowAlert } = useContext(DefaultContext)

  const [isLoading, setIsLoading] = useState(false);
  const [stores, setstores] = useState([]);
  const [storeSettings, setStoreSettings] = useState(null)
  const [menus, setMenus] = useState([])

  const formik = useFormik({
    initialValues: {
      name: '',
      active: '',
      role: '',
      typeCashier: '',
      email: '',
      password: '',
      estabId: '',
      storeId: '',
      stores: [],
      menuSelected: ''
    },
    validate,
    onSubmit: async values => {
      const { name, active, role, typeCashier, email, password, estabId, storeId, stores, menuSelected } = values;

      const data = {
        name: name,
        email: email,
        password: password,
        role: role,
        typeCashier: typeCashier,
        estabId: role === ROLE.ADMIN ? null : estabId,
        storeId: role === ROLE.CASHIER ? storeId : null,
        stores: role === ROLE.COORDINATOR ? stores : null,
        disabled: active === 'inativo',
        menuSelected: menuSelected
      }

      const onSuccess = () => {
        addUserToMenu()
        onShowAlert(PreAlert.success('Registro cadastrado com sucesso'))
        loadUsers();
        setIsClose();
      }
      const onSuccessUpdate = () => {
        updateUserToMenu()
        onShowAlert(PreAlert.success('Registro atualizado com sucesso'))
        loadUsers();
        setIsClose();
      }
      const onError = () => {
        onShowAlert(PreAlert.error('Falhou ao cadastrar o registro'))
      }

      const addUserToMenu = () => {
        const menuToUpdate = menus?.find(menu => menu.id === formik.values.menuSelected)

        if (!menuToUpdate) return
        const users = menuToUpdate?.users || []
        const userAlreadyExist = users.find(user => user === formik.values.email)
        if (!userAlreadyExist) users.push(formik.values.email)

        const menuUpdated = { users }

        new MenuDB(formik.values.estabId)
          .update(formik.values.menuSelected, menuUpdated)
      }

      const updateUserToMenu = () => {
        menus?.forEach(menu => {
          const usersToUpdate = menu?.users || []
          const userAlreadyInMenu = usersToUpdate.find(user => user === formik.values.email)
          if (userAlreadyInMenu) {
            const users = usersToUpdate.filter(user => user !== formik.values.email)
            const menuUpdated = { users }
            new MenuDB(formik.values.estabId)
              .update(menu.id, menuUpdated)
          }
        })

        addUserToMenu()
      }

      setIsLoading(true);
      if (editData) {
        data.uid = editData.uid
        api.put('routes/users', data)
          .then(onSuccessUpdate)
          .catch(onError)
          .finally(() => setIsLoading(false))
      } else {

        api.post('routes/users', data)
          .then(onSuccess)
          .catch((e) => console.log(e))
          .finally(() => setIsLoading(false))
      }
    },
  })




  useEffect(() => {
    if (!open) return formik.resetForm();
    if (editData) {
      const {
        email,
        displayName,
        disabled,
        customClaims,
      } = editData;
      formik.setValues({
        name: displayName,
        active: disabled ? 'inativo' : 'ativo',
        role: customClaims?.role,
        typeCashier: customClaims?.typeCashier,
        email,
        password: 'xxxxxx',
        estabId: customClaims?.estabId,
        stores: customClaims?.stores || [],
      });
    }
  }, [editData, open])

  useEffect(() => {
    async function getStores() {
      if (formik.values.estabId) {
        setstores(await new StoreDB(formik.values.estabId).getAll(orderBy('name', 'asc')));

      } else {
        setstores([])
      }
    }

    getStores();

  }, [formik.values.estabId])


  const onChangeStores = useCallback((stores) => {
    formik.setValues({
      ...formik.values,
      stores
    })
  }, [formik.values])

  const onChangeEstablishment = useCallback((e) => {
    formik.setValues({
      ...formik.values,
      estabId: e.target.value,
      stores: [],
    })
  }, [formik.values])

  const typesCashierOptions = useMemo(() => {
    const TYPE_CASHIER_COPY = structuredClone(TYPE_CASHIER)
    const options = Object.keys(TYPE_CASHIER_COPY).map((key) => ({
      text: convertTypesCashierPTBR(TYPE_CASHIER_COPY[key]),
      value: TYPE_CASHIER_COPY[key],
    }))
    options.unshift({ text: 'Selecione um tipo', value: '' })
    return options;
  }, [])

  const rolesOptions = useMemo(() => {
    const ROLE_COPY = structuredClone(ROLE)

    if (accessLevel < ACCESS_LEVEL.ADMIN)
      delete ROLE_COPY.ADMIN;
    if (accessLevel < ACCESS_LEVEL.MANAGER)
      delete ROLE_COPY.MANAGER;

    const options = Object.keys(ROLE_COPY).map((key) => ({
      text: convertRolesPTBR(ROLE_COPY[key]),
      value: ROLE_COPY[key]
    }))
    options.unshift({ text: 'Selecione uma função', value: '' })
    return options;
  }, [accessLevel])

  const estabOptions = useMemo(() => {
    const options = establishments.map(item => ({ value: item.id, text: item.name }));
    options.unshift({ text: 'Selecione um estabelecimento', value: '' })
    return options
  }, [establishments])

  const storesOptions = useMemo(() => {
    const storesFilter = user.role === ROLE.COORDINATOR
      ? stores.filter(item => user.stores?.includes(item.id))
      : stores;
    return storesFilter.map(item => ({ value: item.id, label: item.name }))
  }, [user, stores])

  const storesOneOptions = useMemo(() => {
    return [
      { value: '', text: 'Selecione a loja' },
      ...storesOptions,
    ];
  }, [storesOptions])

  const showSelectEstab = useMemo(() => (
    formik.values.role === ROLE.MANAGER ||
    formik.values.role === ROLE.COORDINATOR ||
    formik.values.role === ROLE.CASHIER
  ), [formik.values.role])

  const menuOptions = useMemo(() => {
    if (!menus) return
    const formatedMenu = []
    menus.forEach(menu => {
      formatedMenu.push({
        value: menu.id,
        text: menu.name
      })
    })
    return [
      { value: '', text: 'Selecione o Cardápio' },
      ...formatedMenu
    ]
  }, [menus])

  useEffect(() => {
    async function getStoreConfig() {
      if (formik.values.storeId && formik.values.estabId) {
        const config = await new StoreSettingDB(formik.values.estabId, formik.values.storeId).getAll()
        const menus = await new MenuDB(formik.values.estabId).getAll()
        setMenus(menus)
        setStoreSettings(config[0])
      } else {
        setStoreSettings(null)
      }
    }
    getStoreConfig()

  }, [formik.values.storeId])

  // useEffect(() => console.log(
  //   'DEBUG', 
  //   formik.values.menuSelected, 
  //   formik.values.email, 
  //   // formik.values.role, 
  //   // formik.values?.typeCashier,
  //   // formik.values.storeId,
  //   // formik.values.estabId,
  //   // storeSettings,
  //   menus.find(menu => menu.id === formik.values.menuSelected),
  //   )
  //   )

  return (
    <Modal
      id={'modal-register-category'}
      titleId={'Cadastro de Categoria'}
      isOpen={open}
      setIsOpen={setIsOpen}
      isStaticBackdrop={true}
      isScrollable={false}
      isCentered={true}
      size="lg" // 'sm' || 'lg' || 'xl' 
      isAnimation={true}
      onSubmit={formik.handleSubmit}
    >
      <ModalHeader setIsOpen={setIsOpen}>
        <ModalTitle id="register-user">{editData ? 'Atualização de Usuário' : 'Cadastro de Usuário'}</ModalTitle>
      </ModalHeader>
      <ModalBody className='p-5'>
        <form noValidate onSubmit={formik.handleSubmit}>
          {/* Inputs */}
          <div className="row g-4">
            {/* Nome */}
            <FormGroup id="name" label="Nome" className={(formik.values.role === ROLE.CASHIER || formik.values.role === ROLE.MANAGER || formik.values.role === ROLE.COORDINATOR) ? 'col-md-12 ' : 'col-md-8'}>
              <Input
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.name}
                isValid={formik.isValid}
                isTouched={formik.touched.name}
                invalidFeedback={formik.errors.name}
                validFeedback='Assim está bom!'
                placeholder='Ex: João da Silva'
              />
            </FormGroup>

            {/* role */}
            <FormGroup id='role' label='Função' className={(formik.values.role === ROLE.COORDINATOR || formik.values.role === ROLE.ADMIN || !formik.values.role) ? 'col-md-4 mb-4' : ' col-md-6 mb-4'}>
              <Select
                style={{ cursor: 'pointer' }}
                id="role"
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                isValid={formik.isValid}
                isTouched={formik.touched.role}
                invalidFeedback={formik.errors.role}
                value={formik.values.role}
              >
                <Options
                  list={rolesOptions}
                />
              </Select>
            </FormGroup>

            {(formik.values.role === ROLE.CASHIER) && (
              <FormGroup id='typeCashier' label='Tipo' className='col-md-6 mb-4' >
                <Select
                  style={{ cursor: 'pointer' }}
                  id="typeCashier"
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  isValid={formik.isValid}
                  isTouched={formik.touched.typeCashier}
                  invalidFeedback={formik.errors.typeCashier}
                  value={formik.values.typeCashier}
                >
                  <Options
                    list={typesCashierOptions}
                  />
                </Select>
              </FormGroup>
            )}

            {(showSelectEstab) && (
              <FormGroup id="estabId" label="Estabelecimento" className={(formik.values.role === ROLE.COORDINATOR) ? 'col-md-4 mb-4' : ' col-md-6 mb-4'}>
                <Select
                  value={formik.values.estabId}
                  list={estabOptions}
                  onBlur={formik.handleBlur}
                  onChange={onChangeEstablishment}
                  isValid={formik.isValid}
                  invalidFeedback={formik.errors.estabId}
                />
              </FormGroup>
            )}

            {(formik.values.role === ROLE.COORDINATOR) && (
              <FormGroup id="stores" label="Lojas" className={(formik.values.role === ROLE.COORDINATOR) ? 'col-md-4 mb-4' : ' col-md-6 mb-4'}>
                <ListCheckbox
                  id='stores'
                  options={storesOptions}
                  selecteds={formik.values.stores}
                  onChange={onChangeStores}
                />
              </FormGroup>
            )}

            {(formik.values.role === ROLE.CASHIER) && (
              <FormGroup id="storeId" label="Lojas" className="col-md-6 mb-4">
                <Select
                  value={formik.values.storeId}
                  list={storesOneOptions}
                  onChange={formik.handleChange}
                />
              </FormGroup>
            )}

            {
              storeSettings?.menuByUser && formik.values?.typeCashier !== 'hostess' &&
              <FormGroup id="menuSelected" label="Cardápio" className="col-md-6 mb-4">
                <Select
                  value={formik.values.menuSelected}
                  list={menuOptions}
                  onChange={formik.handleChange}
                />
              </FormGroup>
            }

            {/* Email */}
            <FormGroup id="email" label="Email" className='col-md-8 mb-4'>
              <InputGroup>
                <InputGroupText id="inputGroupPrepend">
                  @
                </InputGroupText>
                <Input
                  id="email"
                  ariaDescribedby='inputGroupPrepend'
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.email}
                  isValid={formik.isValid}
                  isTouched={formik.touched.email}
                  invalidFeedback={formik.errors.email}
                  validFeedback='Assim está bom!'
                  placeholder="joaodasilva@email.com"
                  disabled={editData !== null}
                />
              </InputGroup>
            </FormGroup>

            {/* password */}
            <FormGroup
              id='password'
              label='Senha'
              className='col-md-4 mb-4'
            >
              <Input
                type="password"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.password}
                isValid={formik.isValid}
                isTouched={formik.touched.password}
                invalidFeedback={formik.errors.password}
                validFeedback='Assim está bom!'
                placeholder='Senha do Usuário'
                disabled={editData !== null}
              />
            </FormGroup>



            {/* Status */}
            <FormGroup className='col-md-12 mb-4'>
              <Label>Status</Label>
              <ChecksGroup
                isValid={formik.isValid}
                isTouched={formik.touched.active}
                invalidFeedback={formik.errors.active}
              >
                <Checks
                  type="radio"
                  id="active"
                  label="Ativo"
                  name="active"
                  value="ativo"
                  onChange={formik.handleChange}
                  checked={formik.values.active}
                  isInline
                />
                <Checks
                  type="radio"
                  id="active2"
                  label="Inativo"
                  name="active"
                  value="inativo"
                  onChange={formik.handleChange}
                  checked={formik.values.active}
                  isInline
                />
              </ChecksGroup>
            </FormGroup>
          </div>



          {/* Buttons */}
          <div className="row pt-4">
            <div className='d-flex justify-content-end gap-2'>
              <Button
                color="danger"
                icon="cancel"
                rounded={1}
                onClick={setIsClose}
                shadow="sm"
                hoverShadow="sm"
                size="sm"
              >
                Cancelar
              </Button>
              {isLoading ? (
                <Button
                  color="success"
                  rounded={1}
                  hoverShadow="sm"
                  shadow="sm"
                  size="sm"
                >
                  <Spinner
                    color="light"
                    inButton
                    isGrow
                    isSmall
                    size={10}
                    tag="span"
                  >
                    Carregando...
                  </Spinner>
                  Carregando...
                </Button>
              ) : (
                <Button
                  isDisable={!formik.isValid || isLoading}
                  type='submit'
                  color='success'
                  icon="check"
                  rounded={1}
                  hoverShadow="sm"
                  shadow="sm"
                  size="sm"
                >
                  Confirmar
                </Button>
              )}
            </div>
          </div>
        </form>
      </ModalBody>
    </Modal>
  )
}

export default ModalRegisterUsers;