import { Timestamp } from "firebase/firestore";
import { useMemo, useState } from "react";
import Sale from "../database/entities/sales/sale.entity";
import { PAYMENT_TYPE } from '../types/payment';
import PaymentCommand from "../database/entities/sales/paymentCommand";

const useTotalByDay = (sales: Sale[]) => {
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const calculateValues = (items: Sale[]) => {
    let qtdChargeback = 0;
    let totalCash = 0;
    let totalDebit = 0;
    let totalCredit = 0;
    let totalPix = 0;
    let totalMealTicket = 0;
    let totalCourtesy = 0;
    let totalDiscount = 0;
    let count = 0;
    let created_at: Date | undefined;
    let created_at_noFormat: Timestamp | undefined;

    const sumTypeValue = (paymentType: number, value: number) => {
      if (paymentType === PAYMENT_TYPE.CASH) totalCash += value;
      else if (paymentType === PAYMENT_TYPE.CARD_CREDIT) totalCredit += value;
      else if (paymentType === PAYMENT_TYPE.CARD_DEBIT) totalDebit += value;
      else if (paymentType === PAYMENT_TYPE.PIX) totalPix += value;
      else if (paymentType === PAYMENT_TYPE.MEAL_TICKET) totalMealTicket += value;
      else if (paymentType === PAYMENT_TYPE.COURTESY) totalCourtesy += value;
    };

    items.forEach((item) => {
      if (item.reversed) {
        qtdChargeback++;
      } else {
        totalDiscount += item.discountValue || 0;
        if (item.payment_type === PAYMENT_TYPE.DIVIDED)
          item.splitPayment.forEach(item => sumTypeValue(item.payment_type, item.value))
        else
          sumTypeValue(item.payment_type, item.value);
      }
    });

    const total =
      totalCash +
      totalDebit +
      totalCredit +
      totalPix +
      totalMealTicket

    return {
      qtdChargeback,
      cash: totalCash,
      debit: totalDebit,
      credit: totalCredit,
      pix: totalPix,
      mealTicket: totalMealTicket,
      courtesy: totalCourtesy,
      discount: totalDiscount,
      total,
      count,
      created_at,
      created_at_noFormat

    };
  };


  const groupByDay = (items: Sale[]) => {
    const groupedItems: Record<string, ReturnType<typeof calculateValues>> = {};
    items.forEach((item) => {
      const date = item.created_at?.toDate();
      const created_at_noFormat = item.created_at;
      const formattedDate = `${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()}`;
      const key = formattedDate;
      if (!groupedItems[key]) {
        groupedItems[key] = {
          ...calculateValues([item]),
          count: 1,
          created_at: date,
          created_at_noFormat: created_at_noFormat
        };

      } else {
        groupedItems[key].qtdChargeback += calculateValues([item]).qtdChargeback;
        groupedItems[key].cash += calculateValues([item]).cash;
        groupedItems[key].debit += calculateValues([item]).debit;
        groupedItems[key].credit += calculateValues([item]).credit;
        groupedItems[key].pix += calculateValues([item]).pix;
        groupedItems[key].mealTicket += calculateValues([item]).mealTicket;
        groupedItems[key].courtesy += calculateValues([item]).courtesy;
        groupedItems[key].discount += calculateValues([item]).discount;
        groupedItems[key].total += calculateValues([item]).total;
        groupedItems[key].count += 1;
        groupedItems[key].created_at = item.created_at?.toDate();
        groupedItems[key].created_at_noFormat = item.created_at;
      }
    });

    const result = Object.keys(groupedItems).map(key => ({
      ...groupedItems[key],
      date: key,
    }))

    return result;
  };

  const mergeAndSumByDate = (arr1: any[]) => {
    const merged: Record<string, any> = {};

    arr1.forEach((item) => {
      const date = item.date;
      if (!merged[date]) {
        merged[date] = { ...item };
      } else {
        merged[date].qtdChargeback += item.qtdChargeback;
        merged[date].cash += item.cash;
        merged[date].debit += item.debit;
        merged[date].credit += item.credit;
        merged[date].pix += item.pix;
        merged[date].mealTicket += item.mealTicket;
        merged[date].courtesy += item.courtesy;
        merged[date].discount += item.discount;
        merged[date].total += item.total;
        merged[date].count += item.count;

      }
    });

    const result = Object.keys(merged).map((key) => ({
      ...merged[key],
      date: key,
    }));
    return result;
  };

  const salesByDay = useMemo(() => {
    if (!sales) {
      return [];
    }
    return groupByDay(sales as Sale[]);
  }, [sales,]);


  const mergedByDay = useMemo(() => {
    if (!sales) {
      return [];
    }
    return mergeAndSumByDate(salesByDay).sort((a, b) => a.created_at_noFormat.seconds - b.created_at_noFormat.seconds);
  }, [salesByDay]);

  return { mergedByDay, isLoading, setIsLoading };


};



export default useTotalByDay;


