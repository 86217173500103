import React, { memo, useState } from 'react';
import YoutLogo from '../../../assets/img/yourlogo.png'
import AlertCustom from '../AlertCustom';
import './avatar.css';

function Avatar({ id, value, onChange, limit, withVideo }) {
    const photo = value ? value : YoutLogo;

    const [alertVisible, setalertVisible] = useState(false)

    const LIMIT_SIZE_KB = 1000

    const onLocalChange = e => {
        // const [file] = Array.from(e.target.files)
        // console.log(file)
        // const limitSize = LIMIT_SIZE_KB * 1000
        // if(file.size > limitSize)
        //     return setalertVisible(true)
        onChange(e);
    }

    return (
        <>
            <div className="personal-image">
                <label className="label">
                    <input type="file" id={id} accept={"image/*"} multiple onChange={onLocalChange} />
                    <figure className="personal-figure">
                    <div style={ {position: "relative"} } className="personal-avatar rounded">
                        <img src={photo}  style={{zIndex: '5', width: 'auto', height:'auto', maxHeight: '100px'}} alt=""/>
                    </div>
                        <figcaption className="personal-figcaption">
                            <img src="https://raw.githubusercontent.com/ThiagoLuizNunes/angular-boilerplate/master/src/assets/imgs/camera-white.png" alt='imagem da camera' />
                        </figcaption>
                    </figure>
                </label>
            </div>

            <div style={{ position: 'absolute', top: 0 }}>
                <AlertCustom
                    show={alertVisible}
                    icon='ErrorOutline'
                    title='Imagem muito grande'
                    msm={`Limite de ${LIMIT_SIZE_KB} KB por imagem`}
                    color='danger'
                    timer={10000}
                    onClose={() => setalertVisible(false)}
                />
            </div>
        </>
    );
}

export default memo(Avatar)