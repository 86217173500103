import React, { useState, useEffect, memo } from 'react';
import Modal, { ModalBody, ModalHeader, ModalTitle } from '../../bootstrap/Modal';
import { useFormik, F } from 'formik';
import FormGroup from '../../bootstrap/forms/FormGroup';
import Input from '../../bootstrap/forms/Input';
import SubHeader, { SubHeaderLeft, SubHeaderRight } from '../../../layout/SubHeader/SubHeader';
import Button from '../../../components/bootstrap/Button';

import InputGroup, { InputGroupText } from '../../bootstrap/forms/InputGroup';
import Label from '../../bootstrap/forms/Label';
import { Options } from '../../bootstrap/Option';
import Select from '../../bootstrap/forms/Select';
import masks from '../../../helpers/utils/masks';
import Spinner from '../../bootstrap/Spinner';
import apiFiscal from '../../../services/apiFiscal';

const ModalInvoiceDetails = ({ open, setIsOpen, setIsClose, storeSelected }) => {

  //const fileUrl = 'https://firebasestorage.googleapis.com/v0/b/ugetbank-5336b.appspot.com/o/1677589657805-f70c2019-89ab-4710-9f37-c644097943cd.pdf?alt=media&token=c1efb179-341f-4227-a53c-374755cb11f7'

  const [isLoading, setIsLoading] = useState(false);
  const [fileUrl, setFileUrl]= useState('');

  const [error, setError] = useState('');
  const formik = useFormik({
    initialValues: {
      cnpj: '',
      paymentType: '',
      totalPrice: '',
      cpf: '',
      name: '',
      price: '',
      amount: '',
      ncm: '',
      cfop: '',
      cest: '',
      csosn: '',
      cst: '',
      icms: '',
    },
    onSubmit: values => {
      const {
        cnpj,
        paymentType,
        totalPrice,
        cpf,
        name,
        price,
        amount,
        ncm,
        cfop,
        cest,
        csosn,
        cst,
        icms,
      } = values;

      const payload = {
        cnpj: cnpj.replace(/[^0-9]/g, ''),
        payment_type: paymentType,
        total_price: Number((amount * price).toFixed(2)),
        cpf: cpf.replace(/[^0-9]/g, ''),
        products: [
          {
            id: 'external_id',
            name: name,
            price: price,
            amount: amount,
            ncm: ncm,
            cfop: cfop,
            cest: cest,
            csosn: csosn,
            cst: cst,
            icms: icms
          }
        ]
      }

      console.log(payload)
      setIsLoading(true);
      apiFiscal.post('fiscal/store', payload)
        .then((response) => {

          //set fileUrl
          console.log(response)
        }).catch((error) => {
          setIsLoading(false);
          const errorString = JSON.stringify(error.response.data)

          const array = errorString.split(',');
          setError(array)
        })
    }
  })
  useEffect(() => {

    formik.setValues({
      cnpj: storeSelected.cnpj,
      paymentType: '01',
      totalPrice: 0,
      cpf: '40829922008',
      name: 'ÁGUA S/ GÁS 310ML',
      price: 2.50,
      amount: 1,
      ncm: '22011000',
      cfop: '5405',
      cest: '03.005.04',
      csosn: '',
      cst: '60',
      icms: ''
    })
    setIsLoading(false)
  }, [storeSelected, open]);

  return (
    <Modal
      id={'modal-register-totem'}
      titleId={'Cadastro de Totem'}
      isOpen={open}
      setIsOpen={setIsOpen}
      isStaticBackdrop={true}
      isScrollable={false}
      isCentered={true}
      size="xl" // 'sm' || 'lg' || 'xl' 
      isAnimation={true}
      onSubmit={formik.handleSubmit}
    >
      <ModalHeader setIsOpen={setIsOpen}>
        <ModalTitle id="register-totem">{'STATUS FISCAL - EMITA UM NOTA DE TESTE PARA VERIFICAR SE O CADASTRO DA  LOJA.'}</ModalTitle>
      </ModalHeader>
      <ModalBody>
        <form noValidate onSubmit={formik.handleSubmit}>
          <div
            style={{
              maxWidth: '90%'
            }}>
            <h3 
              lassName='fw-bold fs-5 me-3' 
              style={{
                color: '#000'
              }}
            > 
            STATUS: {storeSelected?.fiscal_data?.message ? storeSelected.fiscal_data.message : 'Esta loja não esta configurada para emitir nota fiscal.'}</h3>
          </div>

          <div className="row g-4">
            <FormGroup
              id='cnpj'
              label='CNPJ'
              className='col-md-3'
            >
              <Input
                disabled={true}
                onChange={formik.handleChange}
                value={formik.values?.cnpj}
                isValid={formik.isValid}
                isTouched={formik.touched.cnpj}
                invalidFeedback={formik.errors.cnpj}
                validFeedback='Assim está bom!'
              />
            </FormGroup>

            <FormGroup
              id='paymentType'
              label='Forma de pagamento'
              className='col-md-3'
            >
              <Select
                style={{ cursor: 'pointer' }}
                id="tax_regime"
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                isValid={formik.isValid}
                isTouched={formik.touched.tax_regime}
                value={formik.values.paymentType}
              >
                <Options
                  list={[
                    {
                      text: 'DINHEIRO',
                      value: '01',
                    },
                    {
                      text: 'CARTÃO DE CRÉDITO',
                      value: '03'
                    },
                    {
                      text: 'CARTÃO DE DÉBITO',
                      value: '04'
                    },
                    {
                      text: 'PIX',
                      value: '17'
                    },
                  ]}
                />
              </Select>
            </FormGroup>

            <FormGroup
              id='totalPrice'
              label='Total da compra'
              className='col-md-3'
            >

              <Input
                disabled={true}
                onChange={formik.handleChange}
                value={'R$ ' + (formik.values.amount * formik.values.price).toFixed(2)}
                isValid={formik.isValid}
                isTouched={formik.touched.cnpj}
                invalidFeedback={formik.errors.cnpj}
                validFeedback='Assim está bom!'
                placeholder='R$ 0.00'
              />
            </FormGroup>

            <FormGroup
              id='cpf'
              label='cpf'
              className='col-md-3'
            >
              <Input
                onChange={formik.handleChange}
                value={masks.cpf(formik.values.cpf)}
                isValid={formik.isValid}
                isTouched={formik.touched.cnpj}
                invalidFeedback={formik.errors.cnpj}
                validFeedback='Assim está bom!'
                placeholder='000.000.000-08'
              />
            </FormGroup>

            <FormGroup
              id='name'
              label='Nome do Produto'
              className='col-md-4'
            >
              <Input
                onChange={formik.handleChange}
                value={formik.values.name}
                isValid={formik.isValid}
                isTouched={formik.touched.cnpj}
                invalidFeedback={formik.errors.cnpj}
                validFeedback='Assim está bom!'
                placeholder='nome do Produto'
              />
            </FormGroup>

            <FormGroup
              id='price'
              label='Preço'
              className='col-md-4'
            >
              <Input
                onChange={formik.handleChange}
                value={formik.values.price}
                isValid={formik.isValid}
                isTouched={formik.touched.cnpj}
                invalidFeedback={formik.errors.cnpj}
                validFeedback='Assim está bom!'
                placeholder='R$ 0.00'
              />
            </FormGroup>

            <FormGroup
              id='amount'
              label='Quantidade'
              className='col-md-4'
            >
              <Input
                type={'number'}
                onChange={formik.handleChange}
                value={formik.values.amount}
                isValid={formik.isValid}
                isTouched={formik.touched.cnpj}
                invalidFeedback={formik.errors.cnpj}
                validFeedback='Assim está bom!'
                placeholder='1'
              />
            </FormGroup>

            <FormGroup
              id='ncm'
              label='ncm'
              className='col-md-4'
            >
              <Input
                onChange={formik.handleChange}
                value={formik.values.ncm}
                isValid={formik.isValid}
                isTouched={formik.touched.cnpj}
                invalidFeedback={formik.errors.cnpj}
                validFeedback='Assim está bom!'
                placeholder='34237209000195'
              />
            </FormGroup>

            <FormGroup
              id='cfop'
              label='cfop'
              className='col-md-4'
            >
              <Input
                onChange={formik.handleChange}
                value={formik.values.cfop}
                isValid={formik.isValid}
                isTouched={formik.touched.cnpj}
                invalidFeedback={formik.errors.cnpj}
                validFeedback='Assim está bom!'
                placeholder='34237209000195'
              />
            </FormGroup>

            <FormGroup
              id='cest'
              label='cest'
              className='col-md-4'
            >
              <Input
                onChange={formik.handleChange}
                value={formik.values.cest}
                isValid={formik.isValid}
                isTouched={formik.touched.cnpj}
                invalidFeedback={formik.errors.cnpj}
                validFeedback='Assim está bom!'
                placeholder='34237209000195'
              />
            </FormGroup>

            <FormGroup
              id='csosn'
              label='csosn'
              className='col-md-4'
            >
              <Input
                onChange={formik.handleChange}
                value={formik.values.csosn}
                isValid={formik.isValid}
                isTouched={formik.touched.cnpj}
                invalidFeedback={formik.errors.cnpj}
                validFeedback='Assim está bom!'
                placeholder='34237209000195'
              />
            </FormGroup>

            <FormGroup
              id='cst'
              label='cst'
              className='col-md-4'
            >
              <Input
                onChange={formik.handleChange}
                value={formik.values.cst}
                isValid={formik.isValid}
                isTouched={formik.touched.cnpj}
                invalidFeedback={formik.errors.cnpj}
                validFeedback='Assim está bom!'
                placeholder='34237209000195'
              />
            </FormGroup>

            <FormGroup
              id='icms'
              label='icms'
              className='col-md-4'
            >
              <Input
                onChange={formik.handleChange}
                value={formik.values.icms}
                isValid={formik.isValid}
                isTouched={formik.touched.cnpj}
                invalidFeedback={formik.errors.cnpj}
                validFeedback='Assim está bom!'
                placeholder=''
              />
            </FormGroup>

            <div style={{ display: 'flex', justifyContent: 'center' }}>
              {isLoading ? (
                <Button
                  color='success'
                  type='submit'
                  shadow="sm"
                  hoverShadow="sm"
                  onClick={() => { }}
                  size='lg'
                >
                  <Spinner
                    color="light"
                    inButton
                    isGrow
                    isSmall
                    size={10}
                    tag="span"
                  >
                    Carregando...
                  </Spinner>
                  Carregando...
                </Button>
              ) : (
                  <Button
                    disabled = {storeSelected.fiscal_data.status !=='success' ? true : false}
                    color='success'
                    type='submit'
                    icon='send'
                    shadow="sm"
                    hoverShadow="sm"
                    onClick={() => { }}
                    size='lg'
                  >
                    EMITIR NOTA DE TESTE
                  </Button>

              )}


            </div>

            {error &&
              <div style={{
                maxWidth: '90%',
                textAlign: 'initial',
              }}>
                <h3>
                  {
                    error.map(value =>{
                      return(
                        <><span key={value}>{value}</span><br /></>
                      )
                    })
                  }
                </h3>
              </div>
            }
            <div style={{ height: 600 }}>
              <object data={''} type="application/pdf" width="100%" height="100%" />
            </div>
          </div>
        </form>
      </ModalBody>
    </Modal>
  )
}

export default memo(ModalInvoiceDetails);