import React, { createContext, useContext, useEffect, useState } from "react";
import ComplementSold from "../database/entities/sales/complementSold";
import ProductSold from "../database/entities/sales/productSolds.entity";
import Sale from "../database/entities/sales/sale.entity";
import Toten from "../database/entities/toten.entity";
import ComplementDB from "../database/wrappers/complement";
import ProductDB from "../database/wrappers/product";
import SalesDB from "../database/wrappers/sales";
import TotenDB from "../database/wrappers/toten";
import ResultsContextInterface from "../interface/results";
import dateFormat from 'dateformat';
import { DefaultContext } from "./default";
import { where } from "firebase/firestore";
import { ROLE } from "../types/roles";
import CategoryDB from "../database/wrappers/category";
import CommandDB from "../database/wrappers/command";
import Command from "../database/entities/stores/command";
import PaymentCommandDB from "../database/wrappers/paymentCommand";
import PaymentCommand from "../database/entities/sales/paymentCommand";
import Consumption from "../database/entities/sales/consumption.entity";
import SalesProcess from "../helpers/utils/results/salesProcess";
import { mountWhereDateFilter } from "../helpers/utils/results/others";
import ConsumptionDB from "../database/wrappers/consumptions";
import ConsumptionsProcess from "../helpers/utils/results/consumptionsProcess";
import LogFiscalErrorsDB from "../database/wrappers/logFiscalErrors";
import LogFiscalErrors from "../database/entities/logFiscalErrors.entity";
import ReasonDB from "../database/wrappers/reason";
import Recharge from "../database/entities/recharges";
import RechargesDB from "../database/wrappers/recharges";
import ClientDB from "../database/wrappers/client";
import OrdersIfoodDB from "../database/wrappers/orderIfood";
import { OrderIfood } from "../types/ordersIfood";
import { getAuthToken } from "../services/apiFiscal";
import { OrderRappi } from "../types/orderRappi";
import OrdersRappiDB from "../database/wrappers/orderRappi";
export const ResultsContext = createContext<ResultsContextInterface>({} as any)

export default function ResultsProvider({ children }: any) {

  const [loading, setloading] = useState<boolean>(false)
  const [sales, setsales] = useState<Sale[]>([])
  const [recharges, setrecharges] = useState<Recharge[]>([])
  const [consumptions, setconsumptions] = useState<Consumption[]>([])
  const [paymentCommands, setpaymentCommands] = useState<PaymentCommand[]>([])
  const [commands, setcommands] = useState<Command[]>([])
  const [productsSold, setproductsSold] = useState<ProductSold[] | null>(null)
  const [complementsSold, setcomplementsSold] = useState<ComplementSold[] | null>(null)
  const [productsConsumption, setproductsConsumption] = useState<ProductSold[] | null>(null)
  const [complementsConsumption, setcomplementsConsumption] = useState<ComplementSold[] | null>(null)
  const [productByStore, setproductByStore] = useState<any | null>(null)
  const [complementByStore, setcomplementByStore] = useState<any | null>(null)
  const [logFiscalErrors, setlogFiscalErrors] = useState<LogFiscalErrors[]>([])
  const [ordersIfood, setOrdersIfood] = useState<OrderIfood[]>([])
  const [ordersRappi, setOrdersRappi] = useState<OrderRappi[]>([])

  const [totems, settotems] = useState<Toten[] | null>(null)
  const [totemsDicionary, settotemsDicionary] = useState<any | null>(null)
  const [reasonsDictionary, setReasonsDictionary] = useState<any | null>(null);
  const [clientsDicionary, setClientsDicionary] = useState<any | null>(null);

  const [productsDicionary, setproductsDicionary] = useState<any | null>(null)
  const [complamentsDicionary, setcomplamentsDicionary] = useState<any | null>(null)

  const [eatHere, seteatHere] = useState<number>(0)
  const [toTake, settoTake] = useState<number>(0)

  const [dataFilter, setdataFilter] = useState({
    store: '#',
    firstDate: dateFormat(new Date(), 'yyyy-mm-dd'),
    secondDate: dateFormat(new Date(), 'yyyy-mm-dd'),
  })

  const { user, establishment, storeSelected, stores } = useContext(DefaultContext)

  useEffect(() => {
    if (dataFilter.store) {
      const store = stores.find(item => item.id === dataFilter.store)
      if (store) {
        getAuthToken(store?.cnpj, store?.fiscal_data?.password);
      }
    }
  }, [stores, dataFilter])

  useEffect(() => {
    if (user && establishment && window.location.pathname?.replace('/', '') === 'dashboard') {
      const wheres = user.role === ROLE.COORDINATOR ? [where('storeId', 'in', user.stores)] : [];
      const onSubscribe = new ProductDB(establishment.id).on(async products => {
        const categories = await new CategoryDB(establishment.id).getAll();
        const productsDicionary: any = {};
        products.forEach(product => {
          const category = categories.find(cat => cat.products.includes(product.id as any))
          productsDicionary[product.id] = {
            active: product.active,
            name: product.name,
            image_url: product.image_url,
            price: product.price,
            unit: product.unit,
            type_compound: product.type_compound,
            fiscal_data: product.fiscal_data,
            category: category ? category.name : ' ',
          }
        })
        setproductsDicionary(productsDicionary)
      });
      const onSubscribe2 = new ComplementDB(establishment.id).on(complements => {
        const complamentsDicionary: any = {};
        complements.forEach(data => complamentsDicionary[data.id] = data)
        setcomplamentsDicionary(complamentsDicionary)
      });
      const onSubscribe4 = new TotenDB(establishment.id).on(totems => {
        const totemsDicionary: any = {};
        totems.forEach(data => totemsDicionary[data.id] = data)
        settotemsDicionary(totemsDicionary)
        settotems(totems)
      }, ...wheres)

      const onSubscribe5 = new ClientDB().on(clients => {
        console.log(clients);
        const clientsDicionary: any = {};
        clients.forEach(data => clientsDicionary[data.id] = data)
        setClientsDicionary(clientsDicionary)
      })


      return () => {
        onSubscribe();
        onSubscribe2();
        onSubscribe4();
        onSubscribe5();
      }
    }
  }, [user, establishment, window.location.pathname])

  useEffect(() => {
    const fetchData = async () => {
      if (user && establishment && storeSelected !== null && window.location.pathname?.replace('/', '') === 'dashboard') {
        const reasonDB = new ReasonDB(establishment.id, storeSelected);
        try {
          const reasons = await reasonDB.getAll();
          const reasonsDict: { [key: string]: any } = {};
          reasons.forEach((reason) => {
            reasonsDict[reason.id] = reason;
          });
          setReasonsDictionary(reasonsDict);
        } catch (error) {
        }
      }
    };

    //console.log('dataFilter', dataFilter)

    if (establishment && storeSelected) {
      fetchData();
    }

  }, [user, establishment, dataFilter, storeSelected]);

  useEffect(() => {
    if (user && establishment && window.location.pathname?.replace('/', '') === 'dashboard') {
      // const onSales = (datas: Sale[]) => {
      //   const salesProcess = new SalesProcess(datas);

      //   setsales(datas)
      //   setproductsSold(salesProcess.getProductsSold())
      //   setcomplementsSold(salesProcess.getComplementsSold())
      //   seteatHere(salesProcess.getEatHere())
      //   settoTake(salesProcess.getToTake())
      //   setloading(false)
      // }
      const onConsuptions = (datas: Consumption[]) => {
        const consumptionProcess = new ConsumptionsProcess(datas);

        setconsumptions(datas)
        setproductsConsumption(consumptionProcess.getProductsSold())
        setcomplementsConsumption(consumptionProcess.getComplementsSold())
        setloading(false)
      }

      const onLogFiscalErrors = (datas: LogFiscalErrors[]) => {
        setlogFiscalErrors(datas);
      }

      const onRecharges = (datas: Recharge[]) => {
        setrecharges(datas);
      }

      const wheres = mountWhereDateFilter(dataFilter, user);

      const onSubscribe = new SalesDB(establishment.id).on(
        (sales: Sale[]) => {
          const salesProcess = new SalesProcess(sales);
          setcomplementByStore(salesProcess.getComplementByStore());
          setproductByStore(salesProcess.getProductByStore())
          setproductsSold(salesProcess.getProductsSold())
          setcomplementsSold(salesProcess.getComplementsSold())
          seteatHere(salesProcess.getEatHere())
          settoTake(salesProcess.getToTake())
          setloading(false)

          const salesWithReasons = sales.map((sale) => {
            const reasonId = sale?.reason as string;
            // console.log(reasonsDictionary, 'reasonsDictionary')
            if (reasonId && reasonsDictionary && reasonsDictionary[reasonId]) {
              sale.reasonInfo = reasonsDictionary[reasonId];
              // console.log(sale.reasonInfo, 'reasonID');
            }
            return sale;
          });
          setsales(salesWithReasons)
          // console.log(salesWithReasons, 'salesWithReasons');

        },
        ...wheres
      );
      const onSubscribe2 = new ConsumptionDB(establishment.id).on(
        onConsuptions,
        ...wheres
      );
      const onSubscribe3 = new PaymentCommandDB(establishment.id).on(
        setpaymentCommands,
        ...wheres
      );
      const onSubscribe4 = new CommandDB(establishment.id).on(
        setcommands,
        ...wheres
      );

      const onSubscribe5 = new LogFiscalErrorsDB(establishment.id).on(
        onLogFiscalErrors,
        ...wheres,

      );

      const onSubscribe6 = new RechargesDB(establishment.id).on(
        onRecharges,
        ...wheres
      );

      const onSubscribe7 = new OrdersIfoodDB(establishment.id).on(datas => {
          setOrdersIfood(datas)
        },
        ...wheres
      )

      const onSubscribe8 = new OrdersRappiDB(establishment.id).on(datas => {
        setOrdersRappi(datas)
      },
      ...wheres
    )

      return () => {
        onSubscribe();
        onSubscribe2();
        onSubscribe3();
        onSubscribe4();
        onSubscribe5();
        onSubscribe6();
        onSubscribe7();
        onSubscribe8();
        setloading(true);
      };
    }
  }, [user, establishment, dataFilter])

  return (
    <ResultsContext.Provider value={{
      loading,
      sales,
      recharges,
      consumptions,
      paymentCommands,
      commands,
      productsSold,
      productsDicionary,
      complementsSold,
      productByStore,
      complementByStore,
      logFiscalErrors,
      complamentsDicionary,
      setproductsDicionary,
      setcomplamentsDicionary,
      productsConsumption,
      complementsConsumption,
      totems,
      totemsDicionary,
      clientsDicionary,
      setdataFilter,
      dataFilter,
      eatHere,
      toTake,
      ordersIfood,
      ordersRappi
    }}>
      {children}
    </ResultsContext.Provider>
  );
}