import React, { useState, useContext, useEffect, useCallback } from 'react';
import { useFormik } from 'formik';
import Button from '../../bootstrap/Button';
import FormGroup from '../../bootstrap/forms/FormGroup';
import Input from '../../bootstrap/forms/Input';
import Checks, { ChecksGroup } from '../../bootstrap/forms/Checks';
import Label from '../../bootstrap/forms/Label';
import Modal, { ModalBody, ModalHeader, ModalTitle } from '../../bootstrap/Modal';
import Spinner from '../../bootstrap/Spinner';
// Database
import CategoryDB from '../../../database/wrappers/category';
import MenuDB from '../../../database/wrappers/menu';
import { DefaultContext } from '../../../contexts/default';
import PreAlert from '../../../helpers/utils/preAlert';
import ListCategories from './ListCategories';
import SubCategoryDB from '../../../database/wrappers/subCategory';
import ProductDB from '../../../database/wrappers/product';
import { orderBy, where } from 'firebase/firestore';

const validate = (values) => {
  const errors = {};

  if (!values.name) {
    errors.name = 'Este campo é necessário';
  } else if (values.name.length > 20) {
    errors.name = 'O nome precisa ter 20 caracteres ou menos';
  }


  if (!values.active) {
    errors.active = 'Você precisa escolher o status da categoria';
  }

  return errors;

}

const ModalRegisterMenu = ({ open, setIsOpen, setIsClose, menuSelected }) => {

  const { establishment, onShowAlert } = useContext(DefaultContext)

  const [isLoading, setIsLoading] = useState(false);
  const [products, setproducts] = useState([])
  const [categoriesResponse, setCategoriesResponse] = useState([])
  const [subCategoriesResponse, setSubCategoriesResponse] = useState([])
  const [showCategoryItems, setshowCategoryItems] = useState(false)

  useEffect(() => {
    if(!open) return formik.resetForm();
    if (menuSelected) {
      const { name, active, pos, toten, categories } = menuSelected;

      formik.setValues({
        name: name,
        active: active ? 'ativo' : 'inativo',
        pos,
        toten,
        categories: categories,
      });
    }
  }, [menuSelected, open])

  // Pega as categorias
  useEffect(async () => {
    if (establishment) {
      function sort(a, b) {
        if(a.name > b.name) return 1;
        if(a.name < b.name) return -1;
        return 0;
      }
      async function getCategories() {
        const response = await new CategoryDB(establishment.id).getAll(where('active', '==', true))
        response.sort(sort);
        setCategoriesResponse(response)
      }
      async function getSubCategories() {
        const response = await new SubCategoryDB(establishment.id).getAll(where('active', '==', true))
        response.sort(sort);
        setSubCategoriesResponse(response)
      }
      async function getProducts() {
        const products = await new ProductDB(establishment.id).getAll(where('active', '==', true));
        products.sort(sort);
        setproducts(products);
      }

      getCategories();
      getSubCategories();
      getProducts();
    }
  }, [establishment])

  const formik = useFormik({
    initialValues: {
      name: '',
      active: '',
      pos: false,
      toten: false,
      categories: [],
    },
    validate,
    onSubmit: values => {
      const { name, active, pos, toten, categories } = values;

      const data = {
        name: name,
        active: active === 'ativo',
        pos,
        toten,
        categories: categories
      }

      const onSuccess = () => {
        onShowAlert(PreAlert.success('Registro cadastrado com sucesso'))
        setIsClose();
      }
      const onSuccessUpdate = () => {
        onShowAlert(PreAlert.success('Registro atualizado com sucesso'))
        setIsClose();
      }
      const onError = () => {
        onShowAlert(PreAlert.error('Falhou ao cadastrar o registro'))
      }

      setIsLoading(true);
      if (menuSelected) {
        new MenuDB(establishment.id)
          .update(menuSelected.id, data)
          .then(onSuccessUpdate)
          .catch(onError)
          .finally(() => setIsLoading(false))
      } else {
        new MenuDB(establishment.id)
          .create(data)
          .then(onSuccess)
          .catch(onError)
          .finally(() => setIsLoading(false))
      }
    },
  })

  const onChangeShowCategory = useCallback((show) => setshowCategoryItems(show), [])

  return (
    <Modal
      id={'modal-register-category'}
      titleId={'Cadastro de Menu'}
      isOpen={open}
      setIsOpen={setIsOpen}
      isStaticBackdrop={true}
      isScrollable={false}
      isCentered={true}
      size="lg" // 'sm' || 'lg' || 'xl' 
      isAnimation={true}
      onSubmit={formik.handleSubmit}
    >
      <ModalHeader setIsOpen={setIsOpen}>
        <ModalTitle id="register-menu">{menuSelected ? 'Atualização de Cardápio' : 'Cadastro de Cardápio'}</ModalTitle>
      </ModalHeader>
      <ModalBody className='p-5' style={showCategoryItems ? { opacity: 0.1, backgroundColor: 'black' } : {}}>
        <form noValidate onSubmit={formik.handleSubmit}>
          {/* Inputs */}
          <div className="row pb-2 d-flex">
            {/* Nome */}
            <FormGroup id="name" label="Nome" className='col-md-8'>
              <Input
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.name}
                isValid={formik.isValid}
                isTouched={formik.touched.name}
                invalidFeedback={formik.errors.name}
                validFeedback='Assim está bom!'
                placeholder='Eletrodomésticos, lâmpadas, etc...'
              />
            </FormGroup>

            {/* Status */}
            <FormGroup className='col-md-4'>
              <Label>Status</Label>
              <ChecksGroup
                isValid={formik.isValid}
                isTouched={formik.touched.active}
                invalidFeedback={formik.errors.active}
              >
                <Checks
                  type="radio"
                  id="active"
                  label="Ativo"
                  name="active"
                  value="ativo"
                  onChange={formik.handleChange}
                  checked={formik.values.active}
                  isInline
                />
                <Checks
                  type="radio"
                  id="active2"
                  label="Inativo"
                  name="active"
                  value="inativo"
                  onChange={formik.handleChange}
                  checked={formik.values.active}
                  isInline
                />
              </ChecksGroup>
            </FormGroup>

          </div>
          <div className="row d-flex p-2">
            
            {/* POS */}
            <FormGroup className='col-md-3 mb-4'>
              <Label>POS</Label>
              <ChecksGroup
                isValid={formik.isValid}
                isTouched={formik.touched.active}
                invalidFeedback={formik.errors.active}
              >
                <Checks
                  type="switch"
                  id="pos"
                  name="pos"
                  // value={toten}
                  onChange={formik.handleChange}
                  checked={formik.values.pos}
                  isInline
                />
              </ChecksGroup>
            </FormGroup>

            {/* Totem */}
            <FormGroup className='col-md-3 mb-4'>
              <Label>Totem</Label>
              <ChecksGroup
                isValid={formik.isValid}
                isTouched={formik.touched.active}
                invalidFeedback={formik.errors.active}
              >
                <Checks
                  type="switch"
                  id="toten"
                  name="toten"
                  // value={toten}
                  onChange={formik.handleChange}
                  checked={formik.values.toten}
                  isInline
                />
              </ChecksGroup>
            </FormGroup>
          </div>

          {/* Categorias */}
          {categoriesResponse.length > 0 && (
            <div className='row g-4 mt-1 mb-4'>
              <h5>Categorias</h5>
            </div>
          )}

          <ListCategories
            products={products}
            categories={categoriesResponse}
            subCategories={subCategoriesResponse}
            categoriesSelected={formik.values.categories}
            onChange={formik.handleChange}
            onChangeShowCategory={onChangeShowCategory}
          />

          {/* Buttons */}
          <div className="row pt-4">    
              <div className='d-flex justify-content-end gap-2'>
                <Button
                  color="danger"
                  icon="cancel"
                  rounded={1}
                  onClick={() => { setIsClose(); formik.resetForm() }}
                  shadow="sm"
                  hoverShadow="sm"
                  size="sm"
                >
                  Cancelar
                </Button>
                {isLoading ? (
                  <Button
                    color="success"
                    rounded={1}
                    hoverShadow="sm"
                    shadow="sm"
                    size="sm"
                  >
                    <Spinner
                      color="light"
                      inButton
                      isGrow
                      isSmall
                      size={10}
                      tag="span"
                    >
                      Carregando...
                    </Spinner>
                    Carregando...
                  </Button>
                ) : (
                  <Button
                    isDisable={!formik.isValid && !!formik.submitCount}
                    type='submit'
                    color='success'
                    icon="check"
                    rounded={1}
                    hoverShadow="sm"
                    shadow="sm"
                    size="sm"
                  >
                    Confirmar
                  </Button>
                )}
              </div>
            </div>
        </form>
      </ModalBody>
    </Modal>
  )
}

export default ModalRegisterMenu;