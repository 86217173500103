import React, { useCallback, useContext, useEffect, useState, useMemo } from 'react';
import PageWrapper from '../../../../layout/PageWrapper/PageWrapper';
import SubHeader, { SubHeaderLeft, SubHeaderRight } from '../../../../layout/SubHeader/SubHeader';
import Page from '../../../../layout/Page/Page';
import Breadcrumb from '../../../../components/bootstrap/Breadcrumb';
import { demoPages } from '../../../../menu';
import Card, { CardBody } from '../../../../components/bootstrap/Card';
import ModalRegisterCategories from '../../../../components/modals/Categories/ModalRegister';
import Button from '../../../../components/bootstrap/Button';
import { DefaultContext } from '../../../../contexts/default';
// Database
import CategoryDB from '../../../../database/wrappers/category';
import SelectEstablishment from '../../../../components/MyCustom/SelectEstablishment';
import TableCustom from '../../../../components/MyCustom/TableCustom';
import showNotification from '../../../../components/extras/showNotification';
import notificationBody from '../../../../helpers/utils/functions/notificationBody';
import { orderBy, where } from 'firebase/firestore';
import { ACCESS_LEVEL } from '../../../../types/roles';
import SuggestionDB from '../../../../database/wrappers/suggestion';
import convertArrayToObject from '../../../../utils/convertArrayToObject';
import logo from '../../../../assets/img/logo_uget_black.png'


const Categories = () => {
  const { accessLevel, establishment, onShowAlert, onShowQuestion } = useContext(DefaultContext);

  const [openModalRegister, setOpenModalRegister] = useState(false);
  const [categories, setCategories] = useState([])
  const [categorySelected, setCategorySelected] = useState({})
  const [suggestionsDicionary, setsuggestionsDicionary] = useState({})

  useEffect(() => {
    if (!establishment) return;
    const onSubscribe = new CategoryDB(establishment.id).on(setCategories, orderBy('name', 'asc'));
    const onSubscribe2 = new SuggestionDB(establishment.id).on(datas => setsuggestionsDicionary(convertArrayToObject(datas, 'id')), where('active', '==', true));

    return () => {
      onSubscribe();
      onSubscribe2();
    };
  }, [establishment])

  const handleOpenModalRegister = useCallback(() => {
    setCategorySelected(null)
    setOpenModalRegister(true);
  }, [])

  const handleCloseModalRegister = useCallback(() => {
    setOpenModalRegister(false);
  }, [])

  const handleOpenModalEdit = useCallback((item) => {
    setCategorySelected(item);
    setOpenModalRegister(true)
  }, [])

  const handleOpenModalDelete = useCallback((id) => {
    onShowQuestion({
      title: 'Exclusão de Categoria',
      message: 'Você está excluindo um categoria. Está ação é irreversível. Deseja continuar?',
      onConfirm: () => {
        new CategoryDB(establishment.id)
          .delete(id)
          .then(res => showNotification('', notificationBody('Registro deletado com sucesso'), 'success'))
          .catch(err => showNotification('', notificationBody('Falhou ao deletar o registro'), 'danger'))
      }
    })
  }, [establishment])

  const handleChangeStatus = useCallback((id, active) => {
    new CategoryDB(establishment.id)
      .update(id, {
        active: !active
      })
      .catch(error => onShowAlert({
        title: 'ERRO!',
        msm: 'Não foi possível atualizar o status do produto',
        icon: 'Error',
        color: 'danger'
      }))
  }, [establishment])

  const columns = useMemo(() => ([
    {
      label: 'Imagem',
      field: 'image_url',
      format: row => (
        <>
          {row.image_url ? (
            <img src={row.image_url} alt="Imagem" width={54} height={54} />
          ) : (
            <img src={logo} alt="Imagem" width={60} height='auto' />
          )}
        </>
      ),
      noExport:true,
    },
    {
      label: 'Nome',
      field: 'name',
    },
    {
      label: 'Ordem',
      field: 'order',
    },
    {
      label: 'Descrição',
      field: 'description',
    },
    {
      label: 'Sugestão',
      field: 'suggestionId',
      format: row => !row.suggestionId ? ' ' : suggestionsDicionary[row.suggestionId]?.name || ' ',
      formatExport: value => !value ? ' ' : suggestionsDicionary[value]?.name || ' ',
    },
    {
      label: 'Status',
      field: 'active',
      format: row => (
        <Button
          isLink
          color={row.active ? 'success' : 'danger'}
          icon='Circle'
          onClick={() => handleChangeStatus(row.id, row.active)}
          className='text-nowrap'>
          {row.active ? 'Ativo' : 'Inativo'}
        </Button>
      )
    },
    {
      label: 'Ações',
      field: 'actions',
      format: row => (
        <div className='w-50 d-flex justify-content-between'>
          <Button
            color="light"
            icon="edit"
            shadow="sm"
            hoverShadow="sm"
            size="sm"
            onClick={() => handleOpenModalEdit(row)}
          />
          <Button
            color="danger"
            icon="Trash"
            shadow="sm"
            hoverShadow="sm"
            size="sm"
            onClick={() => handleOpenModalDelete(row.id)}
          />
        </div>
      ),
      noExport: true
    },
  ]), [handleChangeStatus, handleOpenModalEdit, handleOpenModalDelete, suggestionsDicionary])

  return (
    <>
      <PageWrapper title={demoPages.pdvMenu.subMenu.categories.text}>
        <SubHeader>
          <SubHeaderLeft>
            <Breadcrumb
              list={[
                { title: 'Cadastros', to: '/pdv/categories' },
                {
                  title: 'Categorias',
                  to: '/pdv/categories',
                },
              ]}
            />
          </SubHeaderLeft>
          <SubHeaderRight>
            {accessLevel >= ACCESS_LEVEL.MANAGER &&
              <Button
                color='primary'
                icon='plus'
                shadow="sm"
                hoverShadow="sm"
                onClick={handleOpenModalRegister}
              >
                Cadastrar
              </Button>}
          </SubHeaderRight>
        </SubHeader>
        <Page container='fluid'>
          <Card>
            <CardBody className='table-responsive p-5'>
              <SelectEstablishment />

              <TableCustom
                rows={categories}
                columns={columns}
                fileName={'Categorias'}
              />
            </CardBody>
          </Card>
        </Page>
      </PageWrapper>

      <ModalRegisterCategories
        open={openModalRegister}
        setIsOpen={setOpenModalRegister}
        setIsClose={handleCloseModalRegister}
        categorySelected={categorySelected}
      />
    </>
  );
};

export default Categories;
