import React, { useMemo, useContext, useEffect, useState, useCallback } from 'react';
import PageWrapper from '../../../../layout/PageWrapper/PageWrapper';
import SubHeader, { SubHeaderLeft, SubHeaderRight } from '../../../../layout/SubHeader/SubHeader';
import Page from '../../../../layout/Page/Page';
import Breadcrumb from '../../../../components/bootstrap/Breadcrumb';
import { demoPages } from '../../../../menu';
import { DefaultContext } from '../../../../contexts/default';
import Card, { CardBody } from '../../../../components/bootstrap/Card';
import Button from '../../../../components/bootstrap/Button';
import TableCustom from '../../../../components/MyCustom/TableCustom';
import SelectEstablishment from '../../../../components/MyCustom/SelectEstablishment';
import SelectStore from '../../../../components/MyCustom/SelectStore';
import SelectDate from '../../../../components/MyCustom/SelectDate';
import useDarkMode from '../../../../hooks/useDarkMode';
import ClouserCashierDB from '../../../../database/wrappers/clouserCashier';
import Money from '../../../../helpers/utils/money/money';
import dateFormat from 'dateformat';
import FormGroup from '../../../../components/bootstrap/forms/FormGroup';
import Label from '../../../../components/bootstrap/forms/Label';
import Input from '../../../../components/bootstrap/forms/Input';
import { orderBy, where } from 'firebase/firestore';
import RechargesDB from '../../../../database/wrappers/recharges';

const Cashier = () => {

  const { darkModeStatus } = useDarkMode();

  const { establishment, store, onShowAlert } = useContext(DefaultContext)

  const [clouserCashiers, setclouserCashiers] = useState([])
  const [recharges, setRecharges] = useState([])
  const [dateOpen, setdateOpen] = useState(dateFormat(new Date(), 'yyyy-mm-dd'))

  useEffect(() => {
    if(!establishment || !store) return;
    
    const firstDate = new Date(dateOpen)
    firstDate.setDate(firstDate.getDate() + 1)
    firstDate.setHours(0,0,0);

    const secondDate = new Date(dateOpen)
    secondDate.setDate(secondDate.getDate() + 1)
    secondDate.setHours(23,59,59);

    const onSubscriber = new ClouserCashierDB(establishment.id, store.id).on(
      setclouserCashiers,
      where('opening_at', '>', firstDate),
      where('opening_at', '<', secondDate),
      orderBy('opening_at', 'desc')
    )
    return onSubscriber;
  }, [establishment, store, dateOpen])

  useEffect(() => {
    if(!establishment || !store) return;
    console.log('first', clouserCashiers)

    const firstDate = new Date(dateOpen)
    firstDate.setDate(firstDate.getDate() + 1)
    firstDate.setHours(0,0,0);

    const secondDate = new Date(dateOpen)
    secondDate.setDate(secondDate.getDate() + 1)
    secondDate.setHours(23,59,59);

    const onSubscriber = new RechargesDB(establishment.id, store.id).on(
      setRecharges,
      where('created_at', '>', firstDate),
      where('created_at', '<', secondDate),
      orderBy('created_at', 'desc')
    )
    return onSubscriber;
  }, [establishment, store, dateOpen])

  const calculateTotal = useCallback((row) => {
    let total = row.cash || 0
              + row.debit || 0
              + row.credit || 0
              + row.pix || 0;
    if(row.meal_ticket)
      total += row.meal_ticket || 0
    return total;
  }, [])

  const calculateTotalPay = useCallback((row) => {
    return row.cash_return
      - row.bleed || 0
      + row.cash || 0
  }, [])

  const calculateDiffrence = useCallback((row) => {
    return calculateTotalPay(row) - row.paid;
  }, [])

  const onChangeDate = useCallback((e) => setdateOpen(e.target.value), [])

  const columns = useMemo(() => ([
    {
      label: 'Aberto',
      field: 'opening_at',
      format: row => row.opening_at ? dateFormat(row.opening_at.toDate(), 'dd/mm/yyyy HH:MM') : dateFormat(row.created_at.toDate(), 'dd/mm/yyyy HH:MM'),
      formatExport: value => dateFormat(value.toDate(), 'dd/mm/yyyy HH:MM'),
    },
    {
      label: 'Status',
      field: 'closed',
      format: row => (
        <>
          <Button
            isLink
            color={row.closed ? 'danger' : 'success'}
            icon='Circle'
            // onClick={() => handleChangeStatus(row.id, row)}
            className='text-nowrap'>
              {row.closed ? 'Fechado' : 'Aberto'}
          </Button>
        </>
      ),
      formatExport: value => value ? 'Fechado' : 'Aberto',
    },
    {
      label: 'Operador',
      field: 'operator',
    },
    {
      label: 'Terminal',
      field: 'terminal',
    },
    {
      label: 'Troco',
      field: 'cash_return',
      format: row => Money.centsToMaskMoney(row.cash_return || 0),
      formatExport: value => Money.centsToCoin(value),
    },
    {
      label: 'Sangria',
      field: 'bleed',
      format: row => Money.centsToMaskMoney(row.bleed || 0),
      formatExport: value => Money.centsToCoin(value),
    },
    {
      label: 'Dinheiro',
      field: 'cash',
      format: row => Money.centsToMaskMoney(row.cash || 0),
      formatExport: value => Money.centsToCoin(value),
    },
    {
      label: 'Débito',
      field: 'debit',
      format: row => Money.centsToMaskMoney(row.debit || 0),
      formatExport: value => Money.centsToCoin(value),
    },
    {
      label: 'Credito',
      field: 'credit',
      format: row => Money.centsToMaskMoney(row.credit || 0),
      formatExport: value => Money.centsToCoin(value),
    },
    {
      label: 'Cortesia',
      field: '{row}',
      format: row => row.courtesy !== undefined ? Money.centsToMaskMoney(row.courtesy) : Money.centsToMaskMoney(0),
      formatExport: row => row.courtesy !== undefined ? Money.centsToCoin(row.courtesy) : 0,
    },
    {
      label: 'Vale RF',
      field: '{row}',
      format: row => row.meal_ticket !== undefined ? Money.centsToMaskMoney(row.meal_ticket) : Money.centsToMaskMoney(0),
      formatExport: row => row.meal_ticket !== undefined ? Money.centsToCoin(row.meal_ticket) : 0,
    },
    {
      label: 'Pix',
      field: 'pix',
      format: row => Money.centsToMaskMoney(row.pix || 0),
      formatExport: value => Money.centsToCoin(value),
    },
    {
      label: 'R.Dinheiro',
      field: 'R.Dinheiro',
      format: row => row.payment_type === 2 ? Money.centsToMaskMoney(row.value || 0) : Money.centsToMaskMoney(0),
      formatExport: value => Money.centsToCoin(value),
    },
    {
      label: 'R.Cartão',
      field: 'R.Cartão',
      format: row => row.payment_type !== 2 ? Money.centsToMaskMoney(row.value || 0) : Money.centsToMaskMoney(0),
      formatExport: value => Money.centsToCoin(value),
    },
    {
      label: 'Total',
      field: '{row}',
      format: row => Money.centsToMaskMoney(calculateTotal(row)),
      formatExport: row => Money.centsToCoin(calculateTotal(row)),
    },
    {
      label: 'A Pagar',
      field: '{row}',
      format: row => Money.centsToMaskMoney(calculateTotalPay(row)),
      formatExport: row => Money.centsToCoin(calculateTotalPay(row)),
    },
    {
      label: 'Pago',
      field: '{row}',
      format: row => row.closed ? Money.centsToMaskMoney(row.paid) : '',
      formatExport: row => row.closed ? Money.centsToCoin(row.paid) : 0,
    },
    {
      label: 'Diferença',
      field: '{row}',
      format: row => row.closed ? Money.centsToMaskMoney(calculateDiffrence(row)) : '',
      formatExport: row => row.closed ? Money.centsToCoin(calculateDiffrence(row)) : 0,
    },
  ]), [])
 
console.log(clouserCashiers, recharges, 'Eu')
  return (
    <>
      <PageWrapper title={demoPages.store.subMenu.cashier.text}>
        <SubHeader>
          <SubHeaderLeft>
            <Breadcrumb
              list={[
                { title: 'Loja', to: '/dashboard' },
                { title: 'Caixa', to: '/dashboard' },
              ]}
            />
          </SubHeaderLeft>
          <SubHeaderRight>
            <SelectDate />
          </SubHeaderRight>
        </SubHeader>
        <Page container='fluid'>
          <Card>
            <CardBody className='table-responsive p-5'>
              <div className='row d-flex align-items-center mt-4 mx-1 jus' >
                <SelectEstablishment />
                <SelectStore />
              </div>
              <div className='col-2 mt-2 ms-3 mb-4'>
                <FormGroup>
                  <Label htmlFor='period'>Data de Abertura</Label>
                  <Input
                    id='firstDate'
                    type='date'
                    ariaLabel='First Date'
                    placeholder='01/2021'
                    onChange={onChangeDate}
                    value={dateOpen}
                  />
                </FormGroup>
              </div>
              <TableCustom 
                columns={columns} 
                rows={[...clouserCashiers, ...recharges]} 
                fileName={'Caixa'}
              />
            </CardBody>
          </Card>
        </Page>
      </PageWrapper>
    </>
  );
};

export default Cashier;
