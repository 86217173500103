import React, { useState, useEffect, memo, useCallback, useContext, useMemo } from 'react';
import { useFormik } from 'formik';
import FormGroup from '../../bootstrap/forms/FormGroup';
import Input from '../../bootstrap/forms/Input';
import Label from '../../bootstrap/forms/Label';
import Modal, { ModalBody, ModalHeader, ModalTitle } from '../../bootstrap/Modal';
import Checks, { ChecksGroup } from '../../bootstrap/forms/Checks';
import Textarea from '../../bootstrap/forms/Textarea';
import Select from '../../bootstrap/forms/Select';
import masks, { unmask } from '../../../helpers/utils/masks';
import Wizard, { WizardItem } from '../../Wizard';
import Avatar from '../../MyCustom/Avatar';
import Button from '../../bootstrap/Button';
import Popovers from '../../bootstrap/Popovers';
// DataBase
import ProductDB from '../../../database/wrappers/product';
import CategoryComplementDB from '../../../database/wrappers/categoryComplement';
import Money from '../../../helpers/utils/money/money';
import { where } from 'firebase/firestore';
import { DefaultContext } from '../../../contexts/default';
import PreAlert from '../../../helpers/utils/preAlert';
import { convertTrybuIntToPTBR, TRIBUTARY_SITUATION } from '../../../types/fiscal';
import SuggestionDB from '../../../database/wrappers/suggestion';
import { inputOnlyNumbersNoStitch } from '../../../utils/input';
import ProductsAutoFill from '../ProductsAutoFill/ProductsAutoFill';
import SettingDB from '../../../database/wrappers/settings';
import Icon from '../../icon/Icon';


const validate = (values) => {
  console.log(values);
  const errors = {};

  if (!values.name) {
    errors.name = 'Este campo é necessário';
  }

  if (!values.active) {
    errors.active = 'Você precisa escolher o status do produto'
  }

  const price = unmask(values.price);
  if (!values.price) {
    errors.price = 'Este campo é necessário';
  } else if (isNaN(Number(price)) || Number(price) < 0) {
    errors.price = 'O preço precisa ser um número válido e positivo';
  }

  if (!values.unit) {
    errors.unit = 'Este campo é necessário';
  }

  if (!values.classification) {
    errors.classification = 'Este campo é necessário';
  }

  if (values.myTapp && values.myTappMl === '') {
    errors.myTappMl = 'Este campo é necessário';
  }

  // if (isNaN(Number(values.order)) || Number(values.order) < 0 || Number(values.order) > 99) {
  //   errors.order = "Informe um número entre 0 e 99";
  // }

  // Informações de Produto Composto
  // if (values.type_compound.includes('check')) {
  //   if (isNaN(Number(values.product_qtd)) || Number(values.product_qtd) < 0) {
  //     errors.product_qtd = 'A quantidade precisa ser um número válido';
  //   }

  //   if (Number(values.product_unit_value) < 0) {
  //     errors.product_unit_value = 'O valor precisa ser um número válido';
  //   }
  // }

  // Informaçoes Fiscais
  if (!values.fiscal_data_bar_code) {
    errors.fiscal_data_bar_code = 'Este campo é necessário';
  }
  if (!values.fiscal_data_ncm) {
    errors.fiscal_data_ncm = 'Este campo é necessário';
  }
  if (!values.fiscal_data_cst) {
    errors.fiscal_data_cst = 'Este campo é necessário';
  }
  // if (!values.fiscal_data_csosn) {
  //   errors.fiscal_data_csosn = 'Este campo é necessário';
  // }
  // if (!values.fiscal_data_cest) {
  //   errors.fiscal_data_cest = 'Este campo é necessário';
  // }
  if (!values.fiscal_data_cfop) {
    errors.fiscal_data_cfop = 'Este campo é necessário';
  }

  if (!Object.keys(TRIBUTARY_SITUATION).find(key => TRIBUTARY_SITUATION[key] == values.fiscal_data_tributary_situation)) {
    errors.fiscal_data_tributary_situation = 'Selecione uma opção necessário';
  }

  if (values.fiscal_data_tributary_situation === "1") {
    if (!values.fiscal_data_aliq_icms) {
      errors.fiscal_data_aliq_icms = 'Informe a Alíquota do ICMS quando o produto for Tributado';
    }
  }

  return errors;
}

const _selectUnit = [
  { value: 'KG', text: 'KG' },
  { value: 'UN', text: 'UN' },
  { value: 'L', text: 'L' },
  { value: 'ML', text: 'ML' },
  { value: 'G', text: 'G' },
  { value: 'PC', text: 'PC' },
]

const _selectClassification = [
  { value: 'VEGE', text: 'VEGE' },
  { value: 'VEGA', text: 'VEGA' },
  { value: 'ORG', text: 'ORG' },
  { value: 'ACU', text: 'ACU' },
  { value: 'ZER', text: 'ZER' },
  { value: 'INSUMO', text: 'INSUMO' },
]

const ModalRegisterProducts = ({ open, setIsOpen, setIsClose, productSelected }) => {

  const { establishment, onShowAlert, settings } = useContext(DefaultContext)

  const [isLoading, setIsLoading] = useState(false);
  const [productsResponse, setProductsResponse] = useState([]);
  const [categoryComplementsResponse, setCategoryComplementsResponse] = useState([]);
  // Array abaixo é utilizado para armazenar os dados do produto que está compondo o combo quando type_compound for check
  const [productsToCompound, setProductsToCompound] = useState([])
  const [suggestions, setsuggestions] = useState([])




  useEffect(() => {
    if (!open) {
      setProductsToCompound([])
      return formik.resetForm();
    }
    if (productSelected) {
      const {
        active,
        category_complements,
        suggestionId,
        classification,
        complements,
        eat_here,
        customization,
        eat_out,
        order,
        myTapp,
        myTappMl,
        fiscal_data,
        highlights,
        image_url,
        name,
        description,
        price,
        products,
        type_compound,
        ignoreFiscal,
        unit,
      } = productSelected;

      setProductsToCompound(products)

      formik.setValues({
        image_url: image_url ?? null,
        name: name,
        description,
        active: active ? 'ativo' : 'inativo',
        price: Money.centsToCoin(price).toFixed(2),
        unit: unit,
        order: Number(order),
        type_compound: type_compound ? ['check'] : [],
        ignoreFiscal: ignoreFiscal ? ['check'] : [],
        category_complements: category_complements ? ['check'] : [],
        highlights: highlights ? ['check'] : [],
        suggestionId: suggestionId || 'x',
        classification: classification,
        eat_here: eat_here,
        customizable: customization ? customization?.customizable : false,
        customizablePrice: customization ? Money.centsToCoin(customization?.customizablePrice).toFixed(2) : 0,
        customizableColor: customization ? customization?.customizableColor : '#FFFFFF',
        eat_out: eat_out,
        order,
        myTapp: myTapp === undefined ? false : myTapp,
        myTappMl: myTappMl === undefined ? '' : myTappMl.toString(),

        complements: complements,

        // fiscal data
        fiscal_data_bar_code: fiscal_data?.bar_code,
        fiscal_data_ncm: fiscal_data?.ncm,
        fiscal_data_cst: fiscal_data?.cst,
        fiscal_data_csosn: fiscal_data?.csosn,
        fiscal_data_cest: fiscal_data?.cest,
        fiscal_data_cfop: fiscal_data?.cfop,
        fiscal_data_tributary_situation: fiscal_data?.tributary_situation,
        fiscal_data_aliq_icms: fiscal_data?.aliq_icms || '',
        fiscal_data_sku: fiscal_data?.sku || '',

        // propriedades acima: antes de enviar para o backend montar um objeto chamado fiscal_data
      });
    }
  }, [productSelected, open])

  // Pega os produtos
  useEffect(() => {
    if (establishment) {
      function sort(a, b) {
        if (a.name > b.name) return 1;
        if (a.name < b.name) return -1;
        return 0;
      }
      async function getCategoriesComplements() {
        const categoryComplements = await new CategoryComplementDB(establishment.id).getAll(where('active', '==', true))
        categoryComplements.sort(sort);
        setCategoryComplementsResponse(categoryComplements)
      }
      async function getSuggestions() {
        const suggestions = await new SuggestionDB(establishment.id).getAll(where('active', '==', true))
        suggestions.sort(sort);
        setsuggestions(suggestions)
      }

      getCategoriesComplements();
      getSuggestions();
      return new ProductDB(establishment.id).on(setProductsResponse, where('active', '==', true))
    }
  }, [establishment])

  const formik = useFormik({
    initialValues: {
      id: '',
      image: null,
      image_url: null,
      name: '',
      active: '',
      price: '',
      unit: '',
      description: '',
      type_compound: '',
      ignoreFiscal: '',
      category_complements: '',
      suggestions: null,
      suggestionId: 'x',
      highlights: '',
      order: '',
      classification: '',
      internalCode: '',
      eat_here: false,
      customizable: false,
      customizablePrice: '',
      customizableColor: '',
      eat_out: false,
      myTapp: false,
      myTappMl: '',

      // products
      product_qtd: '',
      product_unit: '',
      product_unit_value: '',
      product: '',
      productPrint: false,
      // propriedades acima: antes de enviar para o backend montar um array de objetos

      complements: [], //Categoria de Complementos
      productsAux: {},


      // fiscal data
      fiscal_data_bar_code: '',
      fiscal_data_ncm: '',
      fiscal_data_cst: '',
      fiscal_data_csosn: '',
      fiscal_data_cest: '',
      fiscal_data_cfop: '',
      fiscal_data_aliq_icms: '',
      fiscal_data_tributary_situation: '',
      fiscal_data_sku: '',
      // propriedades acima: antes de enviar para o backend montar um objeto chamado fiscal_data

    },
    validate: validate,
    onSubmit: values => {
      const {
        name,
        active,
        price,
        unit,
        type_compound,
        ignoreFiscal,
        category_complements,
        suggestionId,
        image,
        highlights,
        classification,
        eat_here,
        customizable,
        customizablePrice,
        customizableColor,
        eat_out,
        myTapp,
        order,
        myTappMl,
        description,
        complements,
        internalCode,

        // fiscal data
        fiscal_data_bar_code,
        fiscal_data_ncm,
        fiscal_data_cst,
        fiscal_data_csosn,
        fiscal_data_cest,
        fiscal_data_cfop,
        fiscal_data_aliq_icms,
        fiscal_data_tributary_situation,
        fiscal_data_sku,
        // propriedades acima: antes de enviar para o backend montar um objeto chamado fiscal_data
      } = values

      const price_unmasked = unmask(price)
      const customizationPrice = unmask(customizablePrice)

      const products = productsToCompound ? productsToCompound.map(item => ({
        name: item.name,
        qtd: Number(item.qtd),
        unit: item.unit,
        unit_value: Number(unmask(item.unit_value)),
        product: item.product,
        productPrint: item.productPrint ?? false,
      })) : [];

      const fiscal_data = {
        bar_code: fiscal_data_bar_code,
        ncm: fiscal_data_ncm,
        cst: fiscal_data_cst,
        csosn: fiscal_data_csosn,
        cest: fiscal_data_cest,
        cfop: fiscal_data_cfop,
        aliq_icms: fiscal_data_aliq_icms.replace(',', '.'),
        tributary_situation: fiscal_data_tributary_situation,
        sku: fiscal_data_sku
      };

      const data = {
        image: image === undefined ? null : image,
        name,
        description,
        active: active === 'ativo',
        price: Number(price_unmasked),
        unit,
        type_compound: type_compound.includes('check'),
        ignoreFiscal: ignoreFiscal.includes('check'),
        category_complements: category_complements.includes('check'),
        suggestionId: suggestionId === 'x' ? null : suggestionId,
        highlights: highlights.includes('check'),
        classification: classification,
        internalCode: internalCode || '',
        eat_here,
        customization: {
          customizable,
          customizablePrice: Number(customizationPrice),
          customizableColor,
        },
        eat_out,
        myTapp,
        myTappMl: Number(myTappMl),
        products,
        complements: complements || [],
        fiscal_data,
        order: order ? Number(order) : null,

      }

      console.log(data);
      const onSuccess = () => {
        onShowAlert(PreAlert.success('Registro cadastrado com sucesso'))
        setIsClose();
      }
      const onSuccessUpdate = () => {
        onShowAlert(PreAlert.success('Registro atualizado com sucesso'))
        setIsClose();
      }
      const onError = () => {
        onShowAlert(PreAlert.error('Falhou ao cadastrar o registro'))
      }

      setIsLoading(true)
      if (productSelected) {
        new ProductDB(establishment.id)
          .update(productSelected.id, data)
          .then(onSuccessUpdate)
          .catch(onError)
          .finally(() => setIsLoading(false))
      } else {
        new ProductDB(establishment.id)
          .create(data)
          .then(onSuccess)
          .catch(onError)
          .finally(() => setIsLoading(false))
      }
    },
  });

  const handleChangeOrder = useCallback(e => {
    const order = e.target.value.replace(/\D/g, "");
    formik.setValues({
      ...formik.values,
      order
    })
  }, [formik.values])

  const checkStepIsValid = (step) => {
    if (step === 'step1') {
      if (
        formik.values.name !== '' &&
        formik.values.active !== '' &&
        formik.values.price !== '' &&
        formik.values.unit !== '' &&
        formik.values.classification !== '' && (
          !formik.values.myTapp ||
          (formik.values.myTapp && formik.values.myTappMl !== '')
        )
      ) {
        return false;
      } else {
        return true;
      }
    }
    if (step === 'add_product') {
      if (
        formik.values.product_qtd !== '' &&
        formik.values.product_unit !== '' &&
        formik.values.product_unit_value !== '' &&
        formik.values.product !== ''
      ) {
        return false;
      } else {
        return true;
      }
    }
  }

  const productDicionary = useMemo(() => {
    const productDicionary = {}
    productsResponse.forEach(item => productDicionary[item.id] = item)
    return productDicionary;
  }, [productsResponse])
  const productsNoCombo = useMemo(() => productsResponse.filter(prod => prod.type_compound === false), [productsResponse])
  const productsNoComboOption = useMemo(() => productsNoCombo.map(item => ({ value: item.id, text: item.name })), [productsNoCombo])

  const handleAddProductsToCompound = () => {
    if (
      !formik.values.product_qtd ||
      isNaN(Number(formik.values.product_qtd)) ||
      !formik.values.product_unit ||
      !formik.values.product_unit_value ||
      !formik.values.product
    ) return;
    setProductsToCompound([...productsToCompound,
    {
      qtd: formik.values.product_qtd,
      unit: formik.values.product_unit,
      unit_value: unmask(formik.values.product_unit_value),
      name: productsNoComboOption.find(item => item.value === formik.values.product)?.text || '',
      product: formik.values.product,
      productPrint: formik.values.productPrint,
    }
    ])
    formik.setFieldValue('product_qtd', '')
    formik.setFieldValue('product_unit', '')
    formik.setFieldValue('product_unit_value', '')
    formik.setFieldValue('product', '')
    formik.setFieldValue('productPrint', false)
  }

  const handleRemoveProductsToCompound = (index) => {
    const newProductsToCompound = productsToCompound.filter((item, i) => i !== index)
    setProductsToCompound(newProductsToCompound)
  }

  const handleImage = useCallback((e) => {
    const [file] = Array.from(e.target.files)
    formik.setValues({
      ...formik.values,
      image: file,
      image_url: URL.createObjectURL(file)
    })
  }, [formik.values])

  const HeaderComplements = useMemo(() => (
    <div className='row g-4 py-2'>
      <div className='col-md-1'>
        <p>#</p>
      </div>
      <div className='col-md-3'>
        <p>Nome</p>
      </div>
      <div className='col-md-4'>
        <p>Descrição</p>
      </div>
      <div className='col-md-2'>
        <p>Mínimo</p>
      </div>
      <div className='col-md-2'>
        <p>Maxímo</p>
      </div>
    </div>
  ), [])

  const optionsSituation = useMemo(() => Object.keys(TRIBUTARY_SITUATION).map(key => ({ value: TRIBUTARY_SITUATION[key], text: convertTrybuIntToPTBR(TRIBUTARY_SITUATION[key]) })), [])
  const optionsSuggestion = useMemo(() => {
    const options = suggestions.map(item => ({ value: item.id, text: item.name }))
    options.unshift({ value: 'x', text: 'Nenhuma' })
    return options;
  }, [suggestions])

  const onChangeNumbersNoStitch = useCallback((e) => inputOnlyNumbersNoStitch(formik, e), [formik])

  async function getImageFileFromUrl(imageUrl) {
    const response = await fetch(imageUrl)
    const blob = await response.blob()
    const filename = imageUrl.split('/').pop()
    return new File([blob], filename)
  }

  function onChosenProduct(product) {
    async function updateFormik() {
      await formik.setFieldValue('name', product.name)
      await formik.setFieldValue('unit', product.unit)
      formik.setFieldTouched('name', true, true)
      formik.setFieldTouched('unit', true, true)

      const imageFile = await getImageFileFromUrl(product.image)
      await formik.setFieldValue('image_url', product.image || null)
      await formik.setFieldValue('image', imageFile)
    }

    updateFormik()
  }


  return (
    <Modal
      id={'modal-register-products'}
      titleId={'Cadastro de Produtos'}
      isOpen={open}
      setIsOpen={setIsOpen}
      isStaticBackdrop={true}
      isScrollable={true}
      isCentered={true}
      size="lg" // 'sm' || 'lg' || 'xl' 
      isAnimation={true}
    >
      <ModalHeader className='p-4 mt-2 justify-content-start' setIsOpen={setIsOpen}>
        <ModalTitle id="register-procuts">{productSelected ? 'Atualização de Produtos' : 'Cadastro de Produtos'}</ModalTitle>
      </ModalHeader>
      <ModalBody className='p-0'>
        <Wizard
          isHeader
          color='info'
          onSubmit={formik.handleSubmit}
          className='p-4 m-0'
          isLoading={isLoading}
        >
          <WizardItem id='step1' title='Informações do Produto' required={checkStepIsValid('step1')}>
            {/* Imagem do Produto */}
            <div className='row align-items-center justify-content-center'>
              <FormGroup
                id='image_url'
                ariaLabel='Logo'
              >
                <Avatar
                  id='image_url'
                  value={formik.values.image_url}
                  onChange={handleImage} />
              </FormGroup>
            </div>
            {/* Dados */}
            < div className="row  pb-2 d-flex">
              {/* Nome */}
              <FormGroup id="name" label="Nome" className='col-md-8 mb-4'>
                <Input
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.name}
                  isValid={formik.isValid}
                  isTouched={formik.touched.name}
                  invalidFeedback={formik.errors.name}
                  validFeedback='Assim está bom!'
                  placeholder='Copo de vidro, garrafa, etc.'
                />
              </FormGroup>
              <ProductsAutoFill searchQuery={formik.values.name} onChosenProduct={onChosenProduct} />

              {/* Ordem */}
              <FormGroup id="order" label="Ordem de Exibição" className='col-md-4 mb-4'>
                <Input
                  onChange={handleChangeOrder}
                  onBlur={formik.handleBlur}
                  value={formik.values.order || ''}
                  isValid={formik.isValid}
                  isTouched={formik.touched.order}
                  invalidFeedback={formik.errors.order}
                  validFeedback='Assim está bom!'
                  placeholder='1, 2, 3, 4 etc...'
                />
              </FormGroup>

              {/* Preço */}
              <FormGroup id='price' label='Preço' className='col-md-4 mb-4'>
                <Input
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={masks.money(formik.values.price)}
                  isValid={formik.isValid}
                  isTouched={formik.touched.price}
                  invalidFeedback={formik.errors.price}
                  validFeedback='Assim está bom!'
                  placeholder='50,00'
                />
              </FormGroup>

              {/* Unit */}
              <FormGroup id='unit' label='Unidade' className='col-md-4 mb-4'>
                <Select
                  placeholder='Selecione o tipo'
                  list={_selectUnit}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.unit}
                  isValid={formik.isValid}
                  isTouched={formik.touched.unit}
                  invalidFeedback={formik.errors.unit}
                />
              </FormGroup>

              {/* Classificação */}
              <FormGroup id='classification' label='Classificação' className='col-md-4 mb-4'>
                <Select
                  placeholder='Selecione o tipo'
                  list={_selectClassification}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.classification}
                  isValid={formik.isValid}
                  isTouched={formik.touched.classification}
                  invalidFeedback={formik.errors.classification}
                />
              </FormGroup>

              {/* Sugestão */}
              <FormGroup id='suggestionId' label='Sugestão (Opcional)' className='col-md-4 mb-4'>
                <Select
                  placeholder='Selecione uma sugestão se desejar'
                  list={optionsSuggestion}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.suggestionId}
                  isValid={formik.isValid}
                  isTouched={formik.touched.suggestionId}
                  invalidFeedback={formik.errors.suggestionId}
                />
              </FormGroup>

              {/* Descrição */}
              <FormGroup id="description" label="Descrição" className='col-md-12 mb-4'>
                <Textarea
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.description}
                  isValid={formik.isValid}
                  isTouched={formik.touched.description}
                  invalidFeedback={formik.errors.description}
                  validFeedback='Assim está bom!'
                  placeholder='Informe, se desejar, uma descrição para o produto'
                />
              </FormGroup>

              <Label className='p-2 fs-5 text-black fw-bold'>Informações Adicionais</Label>
              {/* Código Interno */}
              <FormGroup id='internalCode' label='Código Interno' className='col-md-3 mb-4'>
                <Input
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.internalCode}
                  isValid={formik.isValid}
                  isTouched={formik.touched.internalCode}
                  invalidFeedback={formik.errors.internalCode}
                  validFeedback='Assim está bom!'
                  placeholder='00'
                />
              </FormGroup>

              {/* Type Compound */}
              <div className='col-md-3 mb-4'>
                <div className='h-100 d-flex flex-column justify-content-center'>
                  <Label>Produto Composto?</Label>
                  {productsNoCombo.length > 0 ? (
                    <ChecksGroup
                      isValid={formik.isValid}
                      isTouched={formik.touched.type_compound}
                      invalidFeedback={formik.errors.type_compound}>
                      <Checks
                        type="switch"
                        id="type_compound"
                        name="type_compound"
                        value='check'
                        onChange={formik.handleChange}
                        checked={formik.values.type_compound.includes('check')}
                        isInline
                      />
                    </ChecksGroup>
                  ) : (
                    <Popovers desc="Sem produtos cadastrados ainda" placement="bottom" trigger="hover">
                      <ChecksGroup>
                        <Checks
                          type="switch"
                          isInline
                          disabled={productsResponse.length === 0 ? true : false}
                        />
                      </ChecksGroup>
                    </Popovers>
                  )}
                </div>
              </div>

              {/* Category Complements */}
              <div className='col-md-3 mb-4'>
                <div className='h-100 d-flex flex-column justify-content-center'>
                  <Label>Complementos?</Label>
                  {categoryComplementsResponse.length > 0 ? (
                    <ChecksGroup
                      isValid={formik.isValid}
                      isTouched={formik.touched.category_complements}
                      invalidFeedback={formik.errors.category_complements}>
                      <Checks
                        type="switch"
                        id="category_complements"
                        name="category_complements"
                        value='check'
                        onChange={formik.handleChange}
                        checked={formik.values.category_complements.includes('check')}
                        isInline
                      />
                    </ChecksGroup>
                  ) : (
                    <Popovers desc="Sem complementos cadastrados ainda" placement="bottom" trigger="hover">
                      <ChecksGroup>
                        <Checks
                          type="switch"
                          isInline
                          disabled={categoryComplementsResponse.length === 0 ? true : false}
                        />
                      </ChecksGroup>
                    </Popovers>
                  )}
                </div>
              </div>

              {/* Destaque */}
              <div className='col-md-3 mb-4'>
                <div className='h-100 d-flex flex-column justify-content-center'>
                  <Label>Destaque?</Label>
                  <ChecksGroup
                    isValid={formik.isValid}
                    isTouched={formik.touched.highlights}
                    invalidFeedback={formik.errors.highlights}>
                    <Checks
                      type="switch"
                      id="highlights"
                      name="highlights"
                      value='check'
                      onChange={formik.handleChange}
                      checked={formik.values.highlights.includes('check')}
                      isInline
                    />
                  </ChecksGroup>
                </div>
              </div>
              <div className='row col-md-12'>
                <FormGroup className='col-md-4 mb-4'>
                  <Label className='p-2 fs-6 text-black fw-bold'>Produtos MyTapp</Label>
                  <ChecksGroup isInline>
                    <Checks
                      type="switch"
                      id="myTapp"
                      label={formik.values.myTapp ? 'Sim' : 'Não'}
                      name="myTapp"
                      onChange={formik.handleChange}
                      checked={formik.values.myTapp}
                    />
                  </ChecksGroup>
                </FormGroup>
                {formik.values.myTapp && (
                  <FormGroup id='myTappMl' label='Quantidade (ML)' className='col-md-4 mb-4'>
                    <Input
                      onChange={onChangeNumbersNoStitch}
                      onBlur={formik.handleBlur}
                      value={formik.values.myTappMl}
                      isValid={formik.isValid}
                      isTouched={formik.touched.myTappMl}
                      invalidFeedback={formik.errors.myTappMl}
                      validFeedback='Assim está bom!'
                      placeholder='500'
                    />
                  </FormGroup>
                )}
                {/* Comer no local? */}
                {!settings?.ecommerce &&
                  <FormGroup className='col-md-4 mb-4'>
                    <Label className='p-2 fs-6 text-black fw-bold'>Comer no local ?</Label>
                    <ChecksGroup isInline>
                      <Checks
                        type="checkbox"
                        id="eat_here"
                        label="Comer no local"
                        name="eat_here"
                        onChange={formik.handleChange}
                        checked={formik.values.eat_here}
                      />
                      <Checks
                        type="checkbox"
                        id="eat_out"
                        label="Comer fora"
                        name="eat_out"
                        onChange={formik.handleChange}
                        checked={formik.values.eat_out}
                      />
                    </ChecksGroup>
                  </FormGroup>
                }
                {settings?.ecommerce &&
                  <FormGroup className='col-md-4 mb-4'>
                    <Label className='p-2 fs-6 text-black fw-bold'>E-Commerce?</Label>
                    <ChecksGroup isInline>
                      <Checks
                        type="checkbox"
                        id="customizable"
                        label="Personalizável"
                        name="customizable"
                        onChange={formik.handleChange}
                        checked={formik.values.customizable}
                      />
                    </ChecksGroup>
                    {formik?.values?.customizable && ( // Renderiza somente se o checkbox estiver marcado
                      <>
                        <FormGroup id='customizablePrice' label='Preço da personalização' className='row-md-6 mt-3'>
                          <Input
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={masks.money(formik.values.customizablePrice)}
                            isValid={formik.isValid}
                            isTouched={formik.touched.customizablePrice}
                            invalidFeedback={formik.errors.customizablePrice}
                            validFeedback='Assim está bom!'
                            placeholder='50,00'
                          />
                        </FormGroup>
                        <FormGroup id='customizableColor' label='Cor da personalização' className=' row-md-6 mt-3'>
                          <Input
                            type="color"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.customizableColor}
                            isValid={formik.isValid}
                            isTouched={formik.touched.customizableColor}
                            invalidFeedback={formik.errors.customizableColor}
                            validFeedback='Cor selecionada!'
                            placeholder='#000000'
                          />
                        </FormGroup>

                      </>
                    )}
                  </FormGroup>
                }
                {/* Status */}
                <FormGroup className='col-md-4 mb-4'>
                  <Label className={'p-2 fs-6 text-black fw-bold'}>Status</Label>
                  <ChecksGroup
                    isValid={formik.isValid}
                    isTouched={formik.touched.active}
                    invalidFeedback={formik.errors.active}
                  >
                    <Checks
                      type="radio"
                      id="active"
                      label="Ativo"
                      name="active"
                      value="ativo"
                      onChange={formik.handleChange}
                      checked={formik.values.active}
                      isInline
                    />
                    <Checks
                      type="radio"
                      id="active2"
                      label="Inativo"
                      name="active"
                      value="inativo"
                      onChange={formik.handleChange}
                      checked={formik.values.active}
                      isInline
                    />
                  </ChecksGroup>
                </FormGroup>

              </div>

            </div>
          </WizardItem>

          <WizardItem id='step2' title='Composição do combo'>
            <div className="row  pb-2 d-flex p-2">
              {formik.values.type_compound.includes('check') ? (
                <>
                  <div className='col-md-11'>
                    <div className='d-flex'>
                      {/* Produto */}
                      <FormGroup id='product' label='Produto' className='col-md-4 mb-4 px-2'>
                        <Select
                          placeholder='Selecione o produto'
                          list={productsNoComboOption}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.product}
                        // isValid={formik.isValid}
                        // isTouched={formik.touched.product}
                        // invalidFeedback={formik.errors.product}
                        />
                      </FormGroup>

                      {/* Quantidade Disponível */}
                      <FormGroup id='product_qtd' label='Quantidade' className='col-md-2 px-2'>
                        <Input
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.product_qtd?.replace(/\D/g, "")}
                          isValid={formik.isValid}
                          isTouched={formik.touched.product_qtd}
                          invalidFeedback={formik.errors.product_qtd}
                          validFeedback='Assim está bom!'
                          placeholder='100'
                        />
                      </FormGroup>

                      {/* Unit */}
                      <FormGroup id='product_unit' label='Unidade' className='col-md-2 px-2'>
                        <Select
                          placeholder='Selecione o tipo'
                          list={_selectUnit}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.product_unit}
                        // isValid={formik.isValid}
                        // isTouched={formik.touched.product_unit}
                        // invalidFeedback={formik.errors.product_unit}
                        />
                      </FormGroup>

                      {/* Valor por unidade */}
                      <FormGroup id='product_unit_value' label='Valor' className='col-md-2 px-2'>
                        <Input
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={masks.money(formik.values.product_unit_value)}
                          // isValid={formik.isValid}
                          // isTouched={formik.touched.product_unit_value}
                          // invalidFeedback={formik.errors.product_unit_value}
                          // validFeedback='Assim está bom!'
                          placeholder='100'
                        />
                      </FormGroup>
                      <FormGroup className='mx-4' >

                        <Label>Imprimir?</Label>
                        <ChecksGroup
                          isValid={formik.isValid}
                          isTouched={formik.touched.productPrint}
                          invalidFeedback={formik.errors.productPrint}
                          className="mt-2"
                        >
                          <ChecksGroup isInline>
                            <Checks
                              type="switch"
                              id="productPrint"
                              label={formik.values.productPrint ? 'Sim' : 'Não'}
                              name="productPrint"
                              onChange={formik.handleChange}
                              checked={formik.values.productPrint}
                            />
                          </ChecksGroup>
                        </ChecksGroup>
                      </FormGroup>
                    </div>
                  </div>
                  {/* Botão de adicionar produto ao combo */}
                  <div className='col-md-1'>
                    {checkStepIsValid('add_product') ? (
                      <Popovers desc="Por favor, preencha os campos" placement="right" trigger="hover">
                        <div className='d-flex flex-column justify-content-center align-items-center'>
                          {/* // Não apagar o espaço entre as labels */}
                          <Label>⠀⠀⠀⠀⠀⠀⠀⠀⠀</Label>
                          <Button
                            disabled={checkStepIsValid('add_product')}
                            color="light"
                            icon="PlusCircle"
                            shadow="sm"
                            hoverShadow="sm"
                            size="sm"
                            onClick={handleAddProductsToCompound}
                          />
                        </div>
                      </Popovers>
                    ) : (
                      <div className='d-flex flex-column justify-content-center align-items-center'>
                        {/* // Não apagar o espaço entre as labels */}
                        <Label>⠀⠀⠀⠀⠀⠀⠀⠀⠀</Label>
                        <Button
                          disabled={checkStepIsValid('add_product')}
                          color="light"
                          icon="PlusCircle"
                          shadow="sm"
                          hoverShadow="sm"
                          size="sm"
                          onClick={handleAddProductsToCompound}
                        />
                      </div>
                    )}
                  </div>
                  {/* Produtos adicionados */}
                  <div className="col-md-12">
                    {productsToCompound.length > 0 && (
                      <h6>Produtos adicionados</h6>
                    )}
                    {productsToCompound.map((product, index) => (
                      <div className='d-flex justify-content-center align-items-center py-1' key={index}>
                        <div className='d-flex'>
                          <FormGroup className='col-md-4 px-2'>
                            <Input
                              value={productDicionary[product.product]?.name}
                              disabled
                              readOnly
                            />
                          </FormGroup>

                          <FormGroup className='col-md-2 px-2'>
                            <Input
                              value={product.qtd}
                              disabled
                              readOnly
                            />
                          </FormGroup>

                          <FormGroup className='col-md-2 px-2'>
                            <Input
                              value={product.unit}
                              disabled
                              readOnly
                            />
                          </FormGroup>

                          <FormGroup className='col-md-2 px-2'>
                            <Input
                              value={Money.centsToMaskMoney(product.unit_value)}
                              disabled
                              readOnly
                            />
                          </FormGroup>

                          <FormGroup className='col-md-2 px-2'>

                            <Icon icon={product.productPrint ? "Print" : "PrintDisabled"} size="3x" />


                          </FormGroup>
                        </div>
                        <div className='d-flex justify-content-center align-items-center px-2'>
                          <Button
                            color="danger"
                            icon="Trash"
                            shadow="sm"
                            hoverShadow="sm"
                            size="sm"
                            onClick={() => handleRemoveProductsToCompound(index)}
                          />
                        </div>
                      </div>
                    ))}
                  </div>

                </>
              ) : (
                <div className='row g-4 mt-1'>
                  <h6 className='text-center'>Você deve marcar a opção <b className='text-primary'>"Produto Composto?"</b> em <b className='text-primary'>"Informações do Produto"</b> para visualizar esta seção.</h6>
                </div>
              )}
            </div>
          </WizardItem>

          <WizardItem id='step3' title='Categorias de complementos'>
            <div className="row  pb-2 d-flex">
              {formik.values.category_complements.includes('check') ? (
                categoryComplementsResponse.length > 0 ? (
                  <>
                    {HeaderComplements}
                    {categoryComplementsResponse.map((categoryComplement, index) => {
                      return (
                        <div className="row g-4 py-2" key={index} style={{ marginTop: 0 }}>
                          <div className='col-md-1' style={{ marginTop: 0 }}>
                            <div className='h-100 d-flex align-items-center justify-content-center' style={{ marginTop: 3, marginRight: 0 }}>
                              <ChecksGroup style={{ marginRight: 0 }}>
                                <Checks
                                  type="switch"
                                  id="complements"
                                  name="complements"
                                  value={categoryComplement.id}
                                  onChange={formik.handleChange}
                                  checked={formik.values.complements?.includes(categoryComplement.id)}
                                  isInline
                                />
                              </ChecksGroup>
                            </div>
                          </div>
                          <div className='col-md-3' style={{ marginTop: 0 }}>
                            <FormGroup>
                              <Input
                                value={categoryComplement.name}
                                disabled
                                readOnly
                              />
                            </FormGroup>
                          </div>
                          <div className='col-md-4' style={{ marginTop: 0 }}>
                            <FormGroup>
                              <Input
                                value={categoryComplement.description}
                                disabled
                                readOnly
                              />
                            </FormGroup>
                          </div>
                          <div className='col-md-2' style={{ marginTop: 0 }}>
                            <FormGroup>
                              <Input
                                value={categoryComplement.min_item?.toString()}
                                disabled
                                readOnly
                              />
                            </FormGroup>
                          </div>
                          <div className='col-md-2' style={{ marginTop: 0 }}>
                            <FormGroup>
                              <Input
                                value={categoryComplement.max_item?.toString()}
                                disabled
                                readOnly
                              />
                            </FormGroup>
                          </div>
                        </div>
                      )
                    })}
                  </>
                ) : (
                  <div className='row g-4 mt-1'>
                    <h6 className='text-center'>Não há complementos cadastrados para este estabelecimento</h6>
                  </div>
                )
              ) : (
                <div className='row g-4 mt-1'>
                  <h6 className='text-center'>Você deve marcar a opção <b className='text-primary'>"Complementos?"</b> em <b className='text-primary'>"Informações do Produto"</b> para visualizar esta seção.</h6>
                </div>
              )}
            </div>
          </WizardItem>

          <WizardItem id='step5' title='Informações Fiscais'>
            <div className="row  pb-2 d-flex">
              {/* Bar Code */}
              <FormGroup id='fiscal_data_bar_code' label='Código de Barras' className='col-md-4 mb-4'>
                <Input
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.fiscal_data_bar_code}
                  isValid={formik.isValid}
                  isTouched={formik.touched.fiscal_data_bar_code}
                  invalidFeedback={formik.errors.fiscal_data_bar_code}
                  validFeedback='Assim está bom!'
                  placeholder='Ex: 798632654214'

                />
              </FormGroup>

              {/* NCM */}
              <FormGroup id='fiscal_data_ncm' label='NCM' className='col-md-4 mb-4'>
                <Input
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.fiscal_data_ncm}
                  isValid={formik.isValid}
                  isTouched={formik.touched.fiscal_data_ncm}
                  invalidFeedback={formik.errors.fiscal_data_ncm}
                  validFeedback='Assim está bom!'
                  placeholder='Ex: 798632654214'

                />
              </FormGroup>

              {/* CST */}
              <FormGroup id='fiscal_data_cst' label='CST / ICMS' className='col-md-4 mb-4'>
                <Input
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.fiscal_data_cst}
                  isValid={formik.isValid}
                  isTouched={formik.touched.fiscal_data_cst}
                  invalidFeedback={formik.errors.fiscal_data_cst}
                  validFeedback='Assim está bom!'
                  placeholder='Ex: 798632654214'
                />
              </FormGroup>

              {/* CSOSN */}
              <FormGroup id='fiscal_data_csosn' label='CSOSN' className='col-md-4 mb-4'>
                <Input
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.fiscal_data_csosn}
                  isValid={formik.isValid}
                  isTouched={formik.touched.fiscal_data_csosn}
                  invalidFeedback={formik.errors.fiscal_data_csosn}
                  validFeedback='Assim está bom!'
                  placeholder='Ex: 798632654214'
                />
              </FormGroup>

              {/* CEST */}
              <FormGroup id='fiscal_data_cest' label='CEST' className='col-md-4 mb-4'>
                <Input
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.fiscal_data_cest}
                  isValid={formik.isValid}
                  isTouched={formik.touched.fiscal_data_cest}
                  invalidFeedback={formik.errors.fiscal_data_cest}
                  validFeedback='Assim está bom!'
                  placeholder='Ex: 798632654214'
                />
              </FormGroup>

              {/* CFOF */}
              <FormGroup id='fiscal_data_cfop' label='CFOP' className='col-md-4 mb-4'>
                <Input
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.fiscal_data_cfop}
                  isValid={formik.isValid}
                  isTouched={formik.touched.fiscal_data_cfop}
                  invalidFeedback={formik.errors.fiscal_data_cfop}
                  validFeedback='Assim está bom!'
                  placeholder='Ex: 798632654214'
                />
              </FormGroup>

              <FormGroup id='fiscal_data_sku' label='SKU' className='col-md-4 mb-4'>
                <Input
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.fiscal_data_sku}
                  isValid={formik.isValid}
                  isTouched={formik.touched.fiscal_data_sku}
                  invalidFeedback={formik.errors.fiscal_data_sku}
                  validFeedback='Assim está bom!'
                  placeholder='Ex: 12445'
                />
              </FormGroup>

              {/* Situação Tributária */}
              <FormGroup id='fiscal_data_tributary_situation' label='Situação Tributária' className='col-md-4 mb-4'>
                <Select
                  placeholder='Selecione'
                  value={formik.values.fiscal_data_tributary_situation}
                  list={optionsSituation}
                  onChange={formik.handleChange}
                  isValid={formik.isValid}
                  isTouched={formik.touched.fiscal_data_tributary_situation}
                  invalidFeedback={formik.errors.fiscal_data_tributary_situation}
                />
              </FormGroup>

              {(formik.values.fiscal_data_tributary_situation === "1") && (
                <>
                  {/* Aliquota ICMS */}
                  <FormGroup id='fiscal_data_aliq_icms' label='Alíquota ICMS' className='col-md-4 mb-4'>
                    <Input
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.fiscal_data_aliq_icms}
                      isValid={formik.isValid}
                      isTouched={formik.touched.fiscal_data_aliq_icms}
                      invalidFeedback={formik.errors.fiscal_data_aliq_icms}
                      validFeedback='Assim está bom!'
                      placeholder='Ex: 18.00'
                    />
                  </FormGroup>
                </>
              )}

              <Label>Ignorar Fiscal?</Label>
              <ChecksGroup
                isValid={formik.isValid}
                isTouched={formik.touched.ignoreFiscal}
                invalidFeedback={formik.errors.ignoreFiscal}>
                <Checks
                  type="switch"
                  id="ignoreFiscal"
                  name="ignoreFiscal"
                  value='check'
                  onChange={formik.handleChange}
                  checked={formik.values.ignoreFiscal.includes('check')}
                  isInline
                />
              </ChecksGroup>
            </div>
          </WizardItem>
        </Wizard>
      </ModalBody>
    </Modal >
  )
}

export default memo(ModalRegisterProducts);