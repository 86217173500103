import React, { memo, useCallback, useEffect, useMemo, useState } from 'react';
import useFilterData from '../../../hooks/useFilterData';
import useSortableData from '../../../hooks/useSortableData';
import { exportCSVFile } from '../../../utils/exportFile';
import Button from '../../bootstrap/Button';
import FormGroup from '../../bootstrap/forms/FormGroup';
import Input from '../../bootstrap/forms/Input';
import Icon from '../../icon/Icon';
import PaginationButtons, { dataPagination } from '../../PaginationButtons';
import Modal, { ModalHeader, ModalBody } from '../../bootstrap/Modal';
import ModalSectorPdf from '../../modals/SectorPdf/modalSector';

const TableCustom = ({ columns, rows, keyExtractor, noShowHeader, title, noShowPagination = false, fontSize, icon, titlePdf, fileName }) => {
	const [search, setsearch] = useState('');
	const dataFilter = useFilterData(rows, search)
	const [currentPage, setCurrentPage] = useState(1);
	const [perPage, setPerPage] = useState(10);
	const { items, requestSort, getClassNamesFor } = useSortableData(dataFilter);
	const onCurrentPageData = dataPagination(items, currentPage, perPage);
	const [showExportModal, setShowExportModal] = useState(false);
	const [openModalEvent, setOpenModalEvent] = useState(false);

	const handleOpenModalEvent = () => {
		setOpenModalEvent(true)
	}
	const handleCloseModalEvent = () => {
		setOpenModalEvent(false)
	}

	useEffect(() =>
		[search, rows])
	const onExport = useCallback(() => {
		setShowExportModal(true);
	}, []);


	const onExportCSV = useCallback(() => {
		const dataExport = dataFilter.map((row) => {
			const data = {};
			columns.forEach((c) => {
				if (c.noExport) return;
				const field = c.field;
				let cell = c.formatExport
					? c.formatExport(field === '{row}' ? row : row[field])
					: row[field];
				cell = cell === undefined ? ' ' : cell;
				data[c.label.toUpperCase()] = cell;
			});
			return data;
		});
		console.log('dataExport', dataExport)
		exportCSVFile(dataExport, fileName || 'dados')
	}, [columns, dataFilter])

	const columnsRender = useMemo(() => columns.filter(c => !c.hidden), [columns])

	const dataPDF = [{
		fontSize: fontSize,
		title: titlePdf,
		icon: icon,
		columns: columns,
		data: rows,
	}]

	return (
		<>
			<div className='d-flex' style={{ marginBottom: 20 }} >
				<h4>{title}</h4>
				{!noShowHeader &&
					<div className='d-flex flex-grow-1 flex-row-reverse'>
						<Button
							style={{ marginLeft: 20, paddingTop: 0, paddingBottom: 0 }}
							className='text-dark'
							type='button'
							color='warning'
							icon="Download"
							// rounded={1}
							hoverShadow="sm"
							shadow="sm"
							size="sm"
							onClick={onExport}
						>
							Exportar
						</Button>
						<FormGroup id="name" label="" className='col-md-3'>
							<Input
								onChange={e => setsearch(e.target.value)}
								value={search}
								placeholder='Buscar'
							/>
						</FormGroup>
					</div>}
			</div>
			{/* Modal para exportar */}
			<Modal
				id={'modal-register-stores'}
				isOpen={showExportModal}
				setIsOpen={setShowExportModal}
				isStaticBackdrop={true}
				isScrollable={false}
				isCentered={true}
				size="md" // 'sm' || 'lg' || 'xl' 
				isAnimation={true}
			>
				<ModalHeader className="modal-title">
					<h3 className="fw-bold">Exportar</h3>
					<button type="button" className="btn-close " onClick={() => setShowExportModal(false)}></button>
				</ModalHeader>
				<ModalBody >
					<div className='d-flex justify-content-around align-content-center '>
						<Button
							className="bg-danger text-white fs-5 col-md-5  "
							onClick={handleOpenModalEvent}

						>
							<Icon icon='PictureAsPdf'
								size={'2x'} /> PDF
						</Button>

						<Button
							className="bg-success text-white fs-5 col-md-5 "
							onClick={onExportCSV}
						>
							<Icon icon='LibraryBooks'
								size={'2x'} /> CSV</Button>
					</div>
				</ModalBody>
			</Modal >
			<table className='table table-modern'>
				<thead>
					<tr>
						{columnsRender.map((column) => (
							<th key={'tab-head-' + column.label} onClick={() => requestSort(column.field)}>
								{column.label.toUpperCase()}
								<Icon
									size='sm'
									className={getClassNamesFor(column.field)}
									icon='FilterList'
								/>
							</th>
						))}
					</tr>
				</thead>
				<tbody>
					{onCurrentPageData.map((item, index) => (
						<tr key={keyExtractor ? keyExtractor(item) : item.id}>
							{columnsRender.map((c, index2) => (
								<td key={`tab-cell-${item.id}-${index}-${index2}`}>
									{c.format ? c.format(item) : item[c.field]}
								</td>
							))}
						</tr>
					))}
				</tbody>
			</table>
			<ModalSectorPdf
				open={openModalEvent}
				setIsOpen={setOpenModalEvent}
				setIsClose={handleCloseModalEvent}
				data={dataPDF}
				fileName={fileName}
			/>

			{!noShowPagination &&
				<PaginationButtons
					data={items}
					label='items'
					setCurrentPage={setCurrentPage}
					currentPage={currentPage}
					perPage={perPage}
					setPerPage={setPerPage}
				/>
			}
		</>
	);
};

export default memo(TableCustom);
