import { useFormik } from 'formik';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import BtnLoad from '../../../../components/MyCustom/BtnLoad';
import SelectEstablishment from '../../../../components/MyCustom/SelectEstablishment';
import SelectStore from '../../../../components/MyCustom/SelectStore';
import Button from '../../../../components/bootstrap/Button';
import Card, {
  CardBody,
  CardHeader,
  CardLabel,
  CardTitle,
} from '../../../../components/bootstrap/Card';
import Checks, { ChecksGroup } from '../../../../components/bootstrap/forms/Checks';
import FormGroup from '../../../../components/bootstrap/forms/FormGroup';
import Input from '../../../../components/bootstrap/forms/Input';
import Label from '../../../../components/bootstrap/forms/Label';
import { DefaultContext } from "../../../../contexts/default";
import StoreSettingDB from '../../../../database/wrappers/storeSettings';
import Money from '../../../../helpers/utils/money/money';
import PreAlert from '../../../../helpers/utils/preAlert';
import Page from '../../../../layout/Page/Page';
import PageWrapper from '../../../../layout/PageWrapper/PageWrapper';
import { demoPages } from '../../../../menu';

import Alelo from '../../../../assets/img/brands/alelo.svg';
import AmericanExpress from '../../../../assets/img/brands/american-express.svg';
import Banrisul from '../../../../assets/img/brands/banrisul.svg';
import Cabal from '../../../../assets/img/brands/cabal.svg';
import DinersClub from '../../../../assets/img/brands/diners-club.svg';
import Elo from '../../../../assets/img/brands/elo.svg';
import HiperCard from '../../../../assets/img/brands/hipercard.svg';
import Maestro from '../../../../assets/img/brands/maestro.svg';
import MasterCard from '../../../../assets/img/brands/masterCard.svg';
import Sodexo from '../../../../assets/img/brands/sodexo.svg';
import ValeRF from '../../../../assets/img/brands/vale-refeicao.svg';
import Visa from '../../../../assets/img/brands/visa.svg';
import AvatarVideo from '../../../../components/MyCustom/AvatarVideo';

const StoreSettings = () => {

  const [setting, setsetting] = useState(null)
  const [loading, setloading] = useState(false)
  const [loadingDelete, setloadingDelete] = useState(false)

  const { establishment, store, onShowAlert, onShowQuestion } = useContext(DefaultContext);

  const loadData = useCallback(() => {
    new StoreSettingDB(establishment.id, store.id)
      .getAll()
      .then(datas => setsetting(datas[0]))
      .catch(() => setsetting(null))
  }, [establishment, store])

  useEffect(() => {
    if (establishment && store) {
      loadData();
    }
  }, [establishment, store, loadData])

  useEffect(() => {
    if (setting) {
      const {
        backgroundColor,
        secondaryColor,
        terciaryColor,
        fontColor,
        touchColor,
        payment,
        useQrcodeInTicket,
        requestNumber,
        ticketUnique,
        controlStock,
        eatTake,
        menuByUser,
        fiscal,
        nameRequest,
        taxService,
        taxServiceValue,
        minValuePix,
        disableSuggestion,
        imageHome_url,
        imageBanner_url,
        requestCommandNumber,
        touchWhite,
        bannerCards,
        ecommerce,
        descriptionProduct,
        tokenGwp
      } = setting;

      formik.setValues({
        backgroundColor,
        fontColor,
        secondaryColor: secondaryColor || '#FFFFFF',
        terciaryColor: terciaryColor || '#FFFFFF',
        paymentDebit: payment.debit,
        paymentCredit: payment.credit,
        paymentPix: payment.pix,
        paymentPixMatera: payment.pixMatera,
        paymentCash: payment.cash,
        paymentMealTicket: payment.meal_ticket,
        paymentCashless: payment.cashless,
        paymentCourtesy: payment.courtesy,
        brandVisa: bannerCards?.visa || false,
        brandMasterCard: bannerCards?.masterCard || false,
        brandMaestro: bannerCards?.maestro || false,
        brandAmericanExpress: bannerCards?.american_express || false,
        brandElo: bannerCards?.elo || false,
        brandHiperCard: bannerCards?.hiperCard || false,
        brandAlelo: bannerCards?.alelo || false,
        brandSodexo: bannerCards?.sodexo || false,
        brandBanrisul: bannerCards?.banrisul || false,
        brandDinersClub: bannerCards?.diners || false,
        brandVr: bannerCards?.vr || false,
        brandCabal: bannerCards?.cabal || false,
        useQrcodeInTicket,
        requestNumber,
        ticketUnique,
        controlStock: controlStock ? controlStock : false,
        eatTake,
        menuByUser: menuByUser ? menuByUser : false,
        fiscal,
        nameRequest,
        taxService,
        taxServiceValue,
        imageHome: null,
        imageHome_url,
        imageBanner: null,
        imageBanner_url,
        minValuePix: minValuePix ? Money.centsToCoin(minValuePix) : '',
        disableSuggestion,
        requestCommandNumber,
        touchWhite,
        touchColor: touchColor ? touchColor : false,
        ecommerce: ecommerce ? ecommerce : false,
        descriptionProduct: descriptionProduct ? descriptionProduct : false,
        tokenGwp: tokenGwp || "",
      })
    } else {
      formik.resetForm();
    }
  }, [setting])

  const validate = useCallback((values) => {
    const errors = {};

    if (values.taxService && !values.taxServiceValue) {
      errors.taxServiceValue = 'Informe a porcentagem da taxa de serviço'
    }

    return errors;
  }, [])

  const formik = useFormik({
    initialValues: {
      imageHome: null,
      imageHome_url: null,
      imageBanner: null,
      imageBanner_url: null,
      backgroundColor: '#000000',
      fontColor: '#ffffff',
      touchColor: '#ffffff',
      secondaryColor: "#FFFFFF",
      terciaryColor: '#FFFFFF',
      paymentDebit: false,
      paymentCredit: false,
      paymentPix: false,
      paymentPixMatera: false,
      paymentCash: false,
      paymentMealTicket: false,
      paymentCashless: false,
      paymentCourtesy: false,
      brandAlelo: false,
      brandAmericanExpress: false,
      brandBanrisul: false,
      brandDinersClub: false,
      brandElo: false,
      brandHiperCard: false,
      brandMaestro: false,
      brandMasterCard: false,
      brandSodexo: false,
      brandVr: false,
      brandVisa: false,
      brandCabal: false,
      useQrcodeInTicket: false,
      requestNumber: false,
      ticketUnique: false,
      controlStock: false,
      eatTake: false,
      menuByUser: false,
      fiscal: false,
      nameRequest: false,
      taxService: false,
      requestCommandNumber: false,
      taxServiceValue: '',
      minValuePix: '',
      disableSuggestion: false,
      touchWhite: false,
      ecommerce: false,
      descriptionProduct: false,
      tokenGwp: '',
    },
    validate,
    onSubmit: values => {
      const {
        imageHome,
        imageBanner,
        backgroundColor,
        fontColor,
        touchColor,
        secondaryColor,
        terciaryColor,
        paymentDebit,
        paymentCredit,
        paymentPix,
        paymentPixMatera,
        paymentCash,
        paymentMealTicket,
        paymentCashless,
        paymentCourtesy,
        brandAlelo,
        brandAmericanExpress,
        brandBanrisul,
        brandCabal,
        brandDinersClub,
        brandElo,
        brandHiperCard,
        brandMaestro,
        brandMasterCard,
        brandSodexo,
        brandVr,
        brandVisa,
        useQrcodeInTicket,
        requestNumber,
        ticketUnique,
        controlStock,
        eatTake,
        menuByUser,
        fiscal,
        nameRequest,
        taxService,
        taxServiceValue,
        minValuePix,
        disableSuggestion,
        requestCommandNumber,
        touchWhite,
        ecommerce,
        descriptionProduct,
        tokenGwp
      } = values;

      const valueTaxService = Number(taxServiceValue)
      const valueMinValuePix = Money.coinToCents(Number(minValuePix))

      const data = {
        imageHome,
        imageBanner,
        backgroundColor: backgroundColor === undefined ? false : backgroundColor,
        fontColor: fontColor === undefined ? false : fontColor,
        touchColor: touchColor === undefined ? false : touchColor,
        secondaryColor: secondaryColor === undefined ? false : secondaryColor,
        terciaryColor: terciaryColor === undefined ? false : terciaryColor,
        payment: {
          debit: paymentDebit,
          credit: paymentCredit,
          cash: paymentCash,
          pix: paymentPix,
          pixMatera: paymentPixMatera === undefined ? false : paymentPixMatera,
          meal_ticket: paymentMealTicket === undefined ? false : paymentMealTicket,
          cashless: paymentCashless === undefined ? false : paymentCashless,
          courtesy: paymentCourtesy === undefined ? false : paymentCourtesy,
        },
        bannerCards: {
          visa: brandVisa === undefined ? false : brandVisa,
          masterCard: brandMasterCard === undefined ? false : brandMasterCard,
          maestro: brandMaestro === undefined ? false : brandMaestro,
          american_express: brandAmericanExpress === undefined ? false : brandAmericanExpress,
          elo: brandElo === undefined ? false : brandElo,
          hiperCard: brandHiperCard === undefined ? false : brandHiperCard,
          alelo: brandAlelo === undefined ? false : brandAlelo,
          sodexo: brandSodexo === undefined ? false : brandSodexo,
          cabal: brandCabal === undefined ? false : brandCabal,
          banrisul: brandBanrisul === undefined ? false : brandBanrisul,
          diners: brandDinersClub === undefined ? false : brandDinersClub,
          vr: brandVr === undefined ? false : brandVr,
        },
        useQrcodeInTicket: useQrcodeInTicket || false,
        requestNumber,
        ticketUnique,
        controlStock: controlStock === undefined ? false : controlStock,
        eatTake,
        menuByUser: menuByUser === undefined ? false : menuByUser,
        fiscal,
        nameRequest,
        disableSuggestion: disableSuggestion === undefined ? false : disableSuggestion,
        taxService,
        taxServiceValue: isNaN(valueTaxService) ? 0 : valueTaxService,
        minValuePix: isNaN(valueMinValuePix) ? 0 : valueMinValuePix,
        requestCommandNumber: requestCommandNumber === undefined ? false : requestCommandNumber,
        touchWhite: touchWhite || false,
        ecommerce: ecommerce === undefined ? false : ecommerce,
        descriptionProduct: descriptionProduct === undefined ? false : descriptionProduct,
        tokenGwp: tokenGwp || '',
      }

      const onSuccess = () => {
        onShowAlert(PreAlert.success('Registro cadastrado com sucesso'))
      }
      const onSuccessUpdate = () => {
        onShowAlert(PreAlert.success('Registro atualizado com sucesso'))
      }
      const onError = (error) => {
        console.error(error);
        onShowAlert(PreAlert.error('Falhou ao cadastrar o registro'))
      }

      setloading(true);
      if (setting) {
        new StoreSettingDB(establishment.id, store.id)
          .update(setting.id, data)
          .then(onSuccessUpdate)
          .catch(onError)
          .finally(() => setloading(false))
      } else {
        new StoreSettingDB(establishment.id, store.id)
          .create(data)
          .then(onSuccess)
          .catch(onError)
          .finally(() => setloading(false))
      }
    }
  });

  const onDelete = useCallback(() => {
    if (!setting) {
      onShowAlert(PreAlert.error('Não há configurações disponíveis'))
    } else {
      onShowQuestion({
        title: 'Deletar configurações',
        message: 'Tem certeza que deseja deletar as configurações salvas ?',
        onConfirm: () => {
          setloadingDelete(true)
          new StoreSettingDB(establishment.id, store.id)
            .delete(setting.id)
            .then(() => {
              onShowAlert(PreAlert.success('Registro deletado com sucesso'))
              setsetting(null)
            })
            .catch(error => onShowAlert(PreAlert.error('Falhou ao deletar o registro')))
            .finally(() => setloadingDelete(false))
        }
      })
    }
  }, [establishment, store, setting])

  const handleImageHome = useCallback((e) => {
    const [file] = Array.from(e.target.files)
    formik.setValues({
      ...formik.values,
      imageHome: file,
      imageHome_url: URL.createObjectURL(file)
    })
  }, [formik.values])

  const handleImageBanner = useCallback((e) => {
    const [file] = Array.from(e.target.files)
    formik.setValues({
      ...formik.values,
      imageBanner: file,
      imageBanner_url: URL.createObjectURL(file)
    })
  }, [formik.values])

  const onChangePix = useCallback((e) => {
    formik.setFieldValue(
      e.target.id,
      !formik.values[e.target.id]
    )
    formik.setFieldValue(
      e.target.id === 'paymentPix' ? 'paymentPixMatera' : 'paymentPix',
      false
    )
  }, [formik.values.paymentPix, formik.values.paymentPixMatera])

  return (
    <PageWrapper title={demoPages.settings.text}>
      <Page>
        <form onSubmit={formik.handleSubmit}>
          <div className='row d-flex align-items-center mt-4 mx-1' >

            <SelectEstablishment />
            <SelectStore />


            <div className='d-flex flex-row w-100 justify-content-start mb-4'>
              {loading ? (
                <BtnLoad />
              ) : (
                <Button
                  type='submit'
                  color='primary'
                  icon='Save'
                  shadow="sm"
                  hoverShadow="sm"
                >
                  Salvar
                </Button>
              )}
              {loadingDelete ? (
                <BtnLoad />
              ) : (
                <Button
                  className='ms-2'
                  type='buttom'
                  color='danger'
                  rounded={1}
                  icon='Delete'
                  shadow="sm"
                  hoverShadow="sm"
                  onClick={onDelete}
                >
                  Deletar
                </Button>
              )}
            </div>
          </div>
          <div className="row ">
            <div className="col-md-4 g-4">
              <Card>
                <CardHeader>
                  <CardLabel icon='Monitor'>
                    <CardTitle>Totem Ticket</CardTitle>
                  </CardLabel>
                </CardHeader>
                <CardBody>
                  <div className='row'>
                    <div className='col-md-6 mb-4'>
                      <div className='d-flex flex-row justify-content-center'>
                        <label>Tela Inicial</label>
                      </div>
                      <AvatarVideo
                        withVideo={true}
                        id='imageHome_url'
                        value={formik.values.imageHome_url}
                        limit={1000000}
                        onChange={handleImageHome} />
                    </div>
                    <div className='col-md-6 mb-4'>
                      <div className='d-flex flex-row justify-content-center'>
                        <label>Anúncio</label>
                      </div>
                      <AvatarVideo
                        withVideo={true}
                        id='imageBanner_url'
                        value={formik.values.imageBanner_url}
                        limit={500000}
                        onChange={handleImageBanner} />
                    </div>
                  </div>

                </CardBody>
                <CardHeader>
                  <CardLabel icon='ColorLens'>
                    <CardTitle>Configuração do Tema</CardTitle>
                  </CardLabel>
                </CardHeader>

                <div className='col-md-12 d-grid p-4'>
                  <div className='row'>
                    <FormGroup id="backgroundColor" label="Cor Background" className='col-md-4 mb-4'>
                      <Input
                        type='color'
                        ariaDescribedby='inputGroupPrepend'
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.backgroundColor}
                        isValid={formik.isValid}
                        isTouched={formik.touched.backgroundColor}
                        invalidFeedback={formik.errors.backgroundColor}
                        validFeedback='Assim está bom!'
                        placeholder="Ex: #000000"
                      />
                    </FormGroup>
                    <FormGroup id="fontColor" label="Cor da Fonte" className='col-md-4 mb-4'>
                      <Input
                        type='color'
                        ariaDescribedby='inputGroupPrepend'
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.fontColor}
                        isValid={formik.isValid}
                        isTouched={formik.touched.fontColor}
                        invalidFeedback={formik.errors.fontColor}
                        validFeedback='Assim está bom!'
                        placeholder="Ex: #000000"
                      />
                    </FormGroup>
                    <FormGroup id="touchColor" label="Cor toque tela" className='col-md-4 mb-4'>
                      <Input
                        type='color'
                        ariaDescribedby='inputGroupPrepend'
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.touchColor}
                        isValid={formik.isValid}
                        isTouched={formik.touched.touchColor}
                        invalidFeedback={formik.errors.touchColor}
                        validFeedback='Assim está bom!'
                        placeholder="Ex: #000000"
                      />
                    </FormGroup>

                    {formik.values.ecommerce &&
                      <>
                        <FormGroup id="secondaryColor" label="Cor Secundária" className='col-md-4 mb-4'>
                          <Input
                            type='color'
                            ariaDescribedby='inputGroupPrepend'
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.secondaryColor}
                            isValid={formik.isValid}
                            isTouched={formik.touched.secondaryColor}
                            invalidFeedback={formik.errors.secondaryColor}
                            validFeedback='Assim está bom!'
                            placeholder="Ex: #000000"
                          />
                        </FormGroup>

                        <FormGroup id="terciaryColor" label="Cor Terciária" className='col-md-4 mb-4'>
                          <Input
                            type='color'
                            ariaDescribedby='inputGroupPrepend'
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.terciaryColor}
                            isValid={formik.isValid}
                            isTouched={formik.touched.terciaryColor}
                            invalidFeedback={formik.errors.terciaryColor}
                            validFeedback='Assim está bom!'
                            placeholder="Ex: #000000"
                          />
                        </FormGroup>
                      </>
                    }
                  </div>
                </div>
                <CardHeader>
                  <CardLabel icon='CreditCard'>
                    <CardTitle>Pagamento</CardTitle>
                  </CardLabel>
                </CardHeader>
                <div className='col-md-12 d-flex g-4 justify-content-lg-between p-4'>
                  <div className='row g-2'>

                    <div className='d-flex col-md-4 align-items-center'>
                      <Checks
                        type="switch"
                        id="brandVisa"
                        name="brandVisa"
                        onChange={formik.handleChange}
                        checked={formik.values.brandVisa}
                      />
                      <img src={Visa} height={12} />
                    </div>

                    <div className='d-flex col-md-4 align-items-center'>
                      <Checks
                        type="switch"
                        id="brandMasterCard"
                        name="brandMasterCard"
                        onChange={formik.handleChange}
                        checked={formik.values.brandMasterCard}
                      />
                      <img src={MasterCard} height={25} />
                    </div>

                    <div className='d-flex col-md-4 align-items-center'>
                      <Checks
                        type="switch"
                        id="brandMaestro"
                        name="brandMaestro"
                        onChange={formik.handleChange}
                        checked={formik.values.brandMaestro}
                      />
                      <img src={Maestro} height={25} />
                    </div>

                    <div className='d-flex col-md-4 align-items-center'>
                      <Checks
                        type="switch"
                        id="brandElo"
                        name="brandElo"
                        onChange={formik.handleChange}
                        checked={formik.values.brandElo}
                      />
                      <img src={Elo} height={15} />
                    </div>

                    <div className='d-flex col-md-4 align-items-center'>
                      <Checks
                        type="switch"
                        id="brandHiperCard"
                        name="brandHiperCard"
                        onChange={formik.handleChange}
                        checked={formik.values.brandHiperCard}
                      />
                      <img src={HiperCard} height={15} />
                    </div>

                    <div className='d-flex col-md-4 align-items-center'>
                      <Checks
                        type="switch"
                        id="brandAlelo"
                        name="brandAlelo"
                        onChange={formik.handleChange}
                        checked={formik.values.brandAlelo}
                      />
                      <img src={Alelo} height={25} />
                    </div>

                    <div className='d-flex col-md-4 align-items-center'>
                      <Checks
                        type="switch"
                        id="brandSodexo"
                        name="brandSodexo"
                        onChange={formik.handleChange}
                        checked={formik.values.brandSodexo}
                      />
                      <img src={Sodexo} height={15} />
                    </div>

                    <div className='d-flex col-md-4 align-items-center'>
                      <Checks
                        type="switch"
                        id="brandCabal"
                        name="brandCabal"
                        onChange={formik.handleChange}
                        checked={formik.values.brandCabal}
                      />
                      <img src={Cabal} height={15} />
                    </div>
                    <div className='d-flex col-md-4 align-items-center'>
                      <Checks
                        type="switch"
                        id="brandDinersClub"
                        name="brandDinersClub"
                        onChange={formik.handleChange}
                        checked={formik.values.brandDinersClub}
                      />
                      <img src={DinersClub} height={20} />
                    </div>

                    <div className='d-flex col-md-4 align-items-center'>
                      <Checks
                        type="switch"
                        id="brandVr"
                        name="brandVr"
                        onChange={formik.handleChange}
                        checked={formik.values.brandVr}
                      />
                      <img src={ValeRF} height={35} />
                    </div>

                    <div className='d-flex col-md-4 align-items-center'>
                      <Checks
                        type="switch"
                        id="brandBanrisul"
                        name="brandBanrisul"
                        onChange={formik.handleChange}
                        checked={formik.values.brandBanrisul}
                      />
                      <img src={Banrisul} height={12} />
                    </div>

                    <div className='d-flex col-md-4 align-items-center'>
                      <Checks
                        type="switch"
                        id="brandAmericanExpress"
                        name="brandAmericanExpress"
                        onChange={formik.handleChange}
                        checked={formik.values.brandAmericanExpress}
                      />
                      <img src={AmericanExpress} height={50} />
                    </div>

                  </div>
                </div>
              </Card>

            </div>
            <div className='col-md-8 g-4 '>
              <Card >
                <CardHeader>
                  <CardLabel icon='Settings'>
                    <CardTitle>Geral</CardTitle>
                  </CardLabel>
                </CardHeader>
                <CardBody className='p-5'>
                  <div className='col-md-12 d-grid'>
                    <div className='row '>

                      <div className='col-md-6 g-4 '>
                        <Label className='fs-5 text-black fw-bold '> Forma de Pagamento</Label>
                        <ChecksGroup>
                          <Checks
                            type="switch"
                            id="paymentDebit"
                            label="Débito"
                            name="paymentDebit"
                            onChange={formik.handleChange}
                            checked={formik.values.paymentDebit}
                          />
                          <Checks
                            type="switch"
                            id="paymentCredit"
                            label="Crédito"
                            name="paymentCredit"
                            onChange={formik.handleChange}
                            checked={formik.values.paymentCredit}
                          />
                          <Checks
                            type="switch"
                            id="paymentPix"
                            label="PIX (SE)"
                            name="paymentPix"
                            onChange={onChangePix}
                            checked={formik.values.paymentPix}
                          />
                          <Checks
                            type="switch"
                            id="paymentPixMatera"
                            label="PIX (MATERA)"
                            name="paymentPixMatera"
                            onChange={onChangePix}
                            checked={formik.values.paymentPixMatera}
                          />
                          <Checks
                            type="switch"
                            id="paymentCash"
                            label="Dinheiro"
                            name="paymentCash"
                            onChange={formik.handleChange}
                            checked={formik.values.paymentCash}
                          />
                          <Checks
                            type="switch"
                            id="paymentMealTicket"
                            label="Vale Refeição"
                            name="paymentMealTicket"
                            onChange={formik.handleChange}
                            checked={formik.values.paymentMealTicket}
                          />
                          <Checks
                            type="switch"
                            id="paymentCashless"
                            label="Cashless"
                            name="paymentCashless"
                            onChange={formik.handleChange}
                            checked={formik.values.paymentCashless}
                          />
                          <Checks
                            type="switch"
                            id="paymentCourtesy"
                            label="Cortesia"
                            name="paymentCourtesy"
                            onChange={formik.handleChange}
                            checked={formik.values.paymentCourtesy}
                          />
                        </ChecksGroup>
                        <FormGroup id="minValuePix" label="Pix valor mínimo" className='col-md-6'>
                          <Input
                            type='number'
                            ariaDescribedby='inputGroupPrepend'
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.minValuePix}
                            isValid={formik.isValid}
                            isTouched={formik.touched.minValuePix}
                            invalidFeedback={formik.errors.minValuePix}
                            validFeedback='Assim está bom!'
                            placeholder="Ex: 10.00"
                          />
                        </FormGroup>
                        <FormGroup id="tokenGwp" label="Token GWP" className='col-md-6'>
                          <Input
                            ariaDescribedby='inputGroupPrepend'
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.tokenGwp}
                            isValid={formik.isValid}
                            isTouched={formik.touched.tokenGwp}
                            invalidFeedback={formik.errors.tokenGwp}
                            validFeedback='Assim está bom!'
                            placeholder=""
                          />
                        </FormGroup>

                      </div>
                      <div className='col-md-6 g-4 '>
                        <Label className='fs-5 text-black fw-bold '> Pedidos</Label>

                        <div className='col-md-6 g-4'>
                          <Label>Gerar Número do Pedido</Label>
                          <Checks
                            label={formik.values.requestNumber ? 'Ativo' : 'Inativo'}
                            id="requestNumber"
                            name="requestNumber"
                            onChange={formik.handleChange}
                            checked={formik.values.requestNumber}
                            type="switch"
                          />
                          <Label>Solicitar nome no pedido</Label>
                          <Checks
                            label={formik.values.nameRequest ? 'Ativo' : 'Inativo'}
                            id="nameRequest"
                            name="nameRequest"
                            onChange={formik.handleChange}
                            checked={formik.values.nameRequest}
                            type="switch"
                          />
                          <Label>Solicitar N Comanda </Label>
                          <Checks
                            label={formik.values.requestCommandNumber ? 'Ativo' : 'Inativo'}
                            id="requestCommandNumber"
                            name="requestCommandNumber"
                            onChange={formik.handleChange}
                            checked={formik.values.requestCommandNumber}
                            type="switch"
                          />
                          <Label>Ticket Único</Label>
                          <Checks
                            label={formik.values.ticketUnique ? 'Ativo' : 'Inativo'}
                            id="ticketUnique"
                            name="ticketUnique"
                            onChange={formik.handleChange}
                            checked={formik.values.ticketUnique}
                            type="switch"
                          />
                        </div>
                      </div>
                      <div className='col-md-6 g-4'><Label className='fs-5 text-black fw-bold '> Operacional</Label>

                        <div className='col-md-6 g-4'>
                          <Label>Gerar QR Code</Label>
                          <Checks
                            label={formik.values.useQrcodeInTicket ? 'Ativo' : 'Inativo'}
                            id="useQrcodeInTicket"
                            name="useQrcodeInTicket"
                            onChange={formik.handleChange}
                            checked={formik.values.useQrcodeInTicket}
                            type="switch"
                          />
                          <Label>Emitir Nota Fiscal </Label>
                          <Checks
                            label={formik.values.fiscal ? 'Ativo' : 'Inativo'}
                            id="fiscal"
                            name="fiscal"
                            onChange={formik.handleChange}
                            checked={formik.values.fiscal}
                            type="switch"
                          />

                          <Label>Taxa de Serviço</Label>
                          <Checks
                            label={formik.values.taxService ? 'Ativo' : 'Inativo'}
                            id="taxService"
                            name="taxService"
                            onChange={formik.handleChange}
                            checked={formik.values.taxService}
                            type="switch"
                          />
                          <FormGroup id="taxServiceValue" label="Taxa de Serviço (%)" className='col-md-6'>
                            <Input
                              type='number'
                              ariaDescribedby='inputGroupPrepend'
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                              value={formik.values.taxServiceValue}
                              isValid={formik.isValid}
                              isTouched={formik.touched.taxServiceValue}
                              invalidFeedback={formik.errors.taxServiceValue}
                              validFeedback='Assim está bom!'
                              placeholder="Ex: 10%"
                            />
                          </FormGroup>
                          <Label>Cardápio por usuário</Label>
                          <Checks
                            label={formik.values.menuByUser ? 'Ativo' : 'Inativo'}
                            id="menuByUser"
                            name="menuByUser"
                            value={formik.values.menuByUser}
                            onChange={formik.handleChange}
                            checked={formik.values.menuByUser}
                            type="switch"
                          />
                        </div>
                      </div>
                      <div className='col-md-6 g-4'>
                        <Label>Exibir Levar ou Comer </Label>
                        <Checks
                          label={formik.values.eatTake ? 'Ativo' : 'Inativo'}
                          id="eatTake"
                          name="eatTake"
                          onChange={formik.handleChange}
                          checked={formik.values.eatTake}
                          type="switch"
                        />
                        <Label>Desativar Sugestão</Label>
                        <Checks
                          label={formik.values.disableSuggestion ? 'Sim' : 'Não'}
                          id="disableSuggestion"
                          name="disableSuggestion"
                          onChange={formik.handleChange}
                          checked={formik.values.disableSuggestion}
                          type="switch"
                        />
                        <Label>Habilitar Trava de Estoque</Label>
                        <Checks
                          label={formik.values.controlStock ? 'Ativo' : 'Inativo'}
                          id="controlStock"
                          name="controlStock"
                          onChange={formik.handleChange}
                          checked={formik.values.controlStock}
                          type="switch"
                        />

                        <Label>Totem Delivery</Label>
                        <Checks
                          label={formik.values.ecommerce ? 'Ativo' : 'Inativo'}
                          id="ecommerce"
                          name="ecommerce"
                          onChange={formik.handleChange}
                          checked={formik.values.ecommerce}
                          type="switch"
                        />

                        {formik.values.ecommerce &&
                          <>
                            <Label>Descrição na personalização?</Label>
                            <Checks
                              label={formik.values.descriptionProduct ? 'Ativo' : 'Inativo'}
                              id="descriptionProduct"
                              name="descriptionProduct"
                              onChange={formik.handleChange}
                              checked={formik.values.descriptionProduct}
                              type="switch"
                            />
                          </>

                        }
                      </div>
                    </div>
                  </div>
                </CardBody>
              </Card>
            </div>
          </div>
        </form>
      </Page>
    </PageWrapper >
  );
};

export default StoreSettings;
