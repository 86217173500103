import React, { useCallback, useEffect, useState } from 'react'
import { createContext } from "react";
import { getAuth, onAuthStateChanged } from 'firebase/auth'
import AlertCustom from '../components/MyCustom/AlertCustom';
import Establishment from '../database/entities/establishment.entity';
import Store from '../database/entities/store.entity';
import Toten from '../database/entities/toten.entity';
import Menu from '../database/entities/pdv/menu.entity';
import EstablishmentDB from '../database/wrappers/establishment';
import StoreDB from '../database/wrappers/store';
import TotenDB from '../database/wrappers/toten';
import MenuDB from '../database/wrappers/menu';
import AlertCustomInterface from '../interface/alertCustom';
import DefaultContextInterface from '../interface/default.interface';
import Start from '../pages/presentation/start';
import app from '../database/config';
import jwtDecode from 'jwt-decode'
import CustomClains from '../interface/customClains';
import User from '../interface/user';
import { ROLE, roleLevel } from '../types/roles';
import QuestionModalInterface from '../interface/questionModal';
import QuestionModal from '../components/MyCustom/QuestionModal';
import { documentId, orderBy, where } from 'firebase/firestore';
import api, { defineApiUser } from '../services/api';
import Setting from '../database/entities/setting.entity';
import SettingDB from '../database/wrappers/settings';


export const DefaultContext = createContext<DefaultContextInterface>({} as any)

export default function DefaultProvider({ children }: any) {

  const [isMobile, setisMobile] = useState<boolean>(false);
  const [starting, setstarting] = useState<boolean>(true);
  const [accessLevel, setaccessLevel] = useState<number>(-1);
  const [user, setuser] = useState<User | null>(null);
  const [establishments, setestablishments] = useState<Establishment[]>([]);
  const [estabSelected, setestabSelected] = useState<string | null>(null);
  const [establishment, setestablishment] = useState<Establishment | null>(null)
  const [settings, setsettings] = useState<Setting | null>(null);
  const [stores, setstores] = useState<Store[]>([]);
  const [storesDicionary, setstoresDicionary] = useState<any | null>(null);
  const [storeSelected, setstoreSelected] = useState<string | null>(null);
  const [store, setstore] = useState<Store | null>(null);
  const [totens, settotens] = useState<Toten[]>([]);
  const [totenSelected, settotenSelected] = useState<string | null>(null);
  const [toten, settoten] = useState<Toten | null>(null);
  const [menus, setmenus] = useState<Menu[]>([]);
  const [menuSelected, setmenuSelected] = useState<string | null>(null);
  const [menu, setmenu] = useState<Menu | null>(null);
  const [storeInfo, setStoreInfo] = useState({})
  const [showAlert, setshowAlert] = useState<any>({
    visible: false,
    title: '',
    msm: '',
    icon: '',
    color: '',
    timer: 0,
  })
  const [questionModal, setquestionModal] = useState({
    visible: false,
    loading: false,
    title: '',
    message: '',
    onCancel: () => { },
    onConfirm: () => { },
  })
  const [usersDicionary, setusersDicionary] = useState<any>(null);

  useEffect(() => {
    function handleWindowSizeChange() {
      setisMobile(window.innerWidth <= 768);
    }
    window.addEventListener('resize', handleWindowSizeChange);
    return () => {
      window.removeEventListener('resize', handleWindowSizeChange);
    }
  }, []);

  useEffect(() => {
    setStoreInfo({
      merchantId: store?.id_loja_ifood || null,
      catalogId: store?.id_catalog || null,
      establishmentId: estabSelected || null,
      storeId: storeSelected || null
    })
  }, [store,storeSelected,estabSelected])


  useEffect(() => {
    const onSubscribe = onAuthStateChanged(getAuth(app), user => {
      if (user) {
        defineApiUser(user)
        user.getIdToken()
          .then(token => {
            const tokenData = jwtDecode<CustomClains>(token)
            setaccessLevel(roleLevel(tokenData.role))
            setuser({
              uid: user.uid,
              displayName: user.displayName as string,
              email: user.email as string,
              emailVerified: user.emailVerified,
              photoURL: user.photoURL,
              phoneNumber: user.phoneNumber,
              role: tokenData.role as any,
              stores: tokenData.stores,
              totens: tokenData.totens,
              estabId: tokenData.estabId,
            })
            setstarting(false);
          })
      } else {
        defineApiUser(null)
        setuser(null)
        setstarting(false);
      }
    })

    return onSubscribe;
  }, [])

  useEffect(() => {
    if (!user) return;
    if (user.role === ROLE.ADMIN) {
      const onSubscribe = new EstablishmentDB().on(establishments => {
        setestablishments(establishments)
        // setestabSelected(establishments[0]?.id)
      }, orderBy('name', 'asc'))
      return onSubscribe;
    } else {
      new EstablishmentDB().get(user.estabId as string)
        .then(establishment => {
          setestablishments([establishment])
          setestabSelected(establishment.id)
        })
        .catch(error => console.error(error))
    }

  }, [user])

  useEffect(() => {
    if (!user) return;
    api.get('routes/users')
      .then(res => {
        const usersDicionary: any = {};
        res.data.forEach((item: any) => usersDicionary[item.uid] = item)
        setusersDicionary(usersDicionary)
      })
      .catch(error => console.error(error))
  }, [user])

  useEffect(() => {
    if (estabSelected && user) {
      const onStores = (stores: Store[]) => {
        const storesDicionary: any = {};
        stores.forEach(store => storesDicionary[store.id] = store)
        setstoresDicionary(storesDicionary)
        setstoreSelected(stores[0]?.id)
        setstores(stores)
      }
      const onMenus = (menus: Menu[]) => {
        menus.sort((a, b) => {
          if (a.name > b.name) return 1;
          if (a.name < b.name) return -1;
          return 0;
        })
        setmenuSelected(menus[0]?.id)
        setmenus(menus)
      }

      setestablishment(establishments.find(estab => estab.id === estabSelected) as Establishment)
      const onSubscribe = user.role === ROLE.COORDINATOR ? new StoreDB(estabSelected).on(onStores, where(documentId(), 'in', user.stores))
        : new StoreDB(estabSelected).on(onStores, orderBy('name', 'asc'))
      const onSubscribe2 = new MenuDB(estabSelected).on(onMenus, where('active', '==', true))

      return () => {
        onSubscribe();
        onSubscribe2();
      }
    }
  }, [estabSelected, user])

  useEffect(() => {
    if (storeSelected) {
      setstore(stores.find(item => item.id === storeSelected) as Store)

    }
  }, [storeSelected, stores])

  useEffect(() => {
    if (estabSelected) {
      const onSubscribe = new SettingDB(estabSelected).on((settings) => {
        setsettings(settings[0])
      })
      return () => {
        onSubscribe();
      }
    }
  }, [estabSelected])


  useEffect(() => {
    if (estabSelected) {
      setestablishment(establishments.find(estab => estab.id === estabSelected) as Establishment)
      const onSubscribe = new TotenDB(estabSelected).on(totens => {
        settotenSelected(totens[0]?.id)
        settotens(totens)
      }, orderBy('name', 'asc'))
      const onSubscribe2 = new MenuDB(estabSelected).on(menus => {
        menus.sort((a, b) => {
          if (a.name > b.name) return 1;
          if (a.name < b.name) return -1;
          return 0;
        })
        setmenuSelected(menus[0]?.id)
        setmenus(menus)
      }, where('active', '==', true))

      return () => {
        onSubscribe();
        onSubscribe2();
      }
    }
  }, [estabSelected])

  useEffect(() => {
    if (totenSelected) {
      settoten(totens.find(item => item.id === totenSelected) as Toten)
    }
  }, [totenSelected, totens])

  useEffect(() => {
    if (menuSelected) {
      setmenu(menus.find(item => item.id === menuSelected) as Menu)
    }
  }, [menuSelected, menus])

  const onShowAlert = useCallback(({ icon, title, msm, color, timer }: AlertCustomInterface) => setshowAlert({
    visible: true,
    icon, title, msm, color, timer
  }), [])

  const onShowQuestion = useCallback(({ title, message, onCancel, onConfirm }: QuestionModalInterface) => setquestionModal({
    visible: true,
    loading: false,
    title, message, onCancel, onConfirm
  } as any), [])

  if (starting) {
    return (
      <Start />
    );
  }


  return (
    <DefaultContext.Provider value={{
      accessLevel,
      establishment,
      setestablishment,
      establishments,
      estabSelected,
      setestabSelected,
      settings,
      store,
      stores,
      storesDicionary,
      storeSelected,
      setstoreSelected,
      toten,
      totens,
      totenSelected,
      settotenSelected,
      menu,
      menus,
      menuSelected,
      setmenuSelected,
      onShowAlert,
      onShowQuestion,
      user,
      isMobile,
      usersDicionary,
      storeInfo,
    }}>
      {children}

      <AlertCustom
        show={showAlert.visible}
        icon={showAlert.icon}
        title={showAlert.title}
        msm={showAlert.msm}
        color={showAlert.color}
        timer={showAlert.timer || 10000}
        onClose={() => setshowAlert({ ...showAlert, visible: false })}
      />

      <QuestionModal
        open={questionModal.visible}
        loading={questionModal.loading}
        title={questionModal.title}
        message={questionModal.message}
        onCancel={questionModal.onCancel}
        onConfirm={questionModal.onConfirm}
        onClose={() => setquestionModal({ visible: false } as any)}
      />
    </DefaultContext.Provider>
  );
}