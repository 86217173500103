import React, { useRef, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import SelectEstablishment from '../../../../components/MyCustom/SelectEstablishment';
import TableCustom from '../../../../components/MyCustom/TableCustom';
import Breadcrumb from '../../../../components/bootstrap/Breadcrumb';
import Button from '../../../../components/bootstrap/Button';
import Card, { CardBody } from '../../../../components/bootstrap/Card';
import Icon from '../../../../components/icon/Icon';
import ModalRegisterClients from '../../../../components/modals/Clients/ModalRegister';
import ModalSaldo from '../../../../components/modals/Clients/ModalSaldo';
import { DefaultContext } from '../../../../contexts/default';
import ClientEstabDB from '../../../../database/wrappers/clientEstab';
import masks from '../../../../helpers/utils/masks';
import PreAlert from '../../../../helpers/utils/preAlert';
import Page from '../../../../layout/Page/Page';
import PageWrapper from '../../../../layout/PageWrapper/PageWrapper';
import SubHeader, { SubHeaderLeft, SubHeaderRight } from '../../../../layout/SubHeader/SubHeader';
import { demoPages } from '../../../../menu';
import { ACCESS_LEVEL } from '../../../../types/roles';
import Money from '../../../../helpers/utils/money/money';
import ModalRegisterImport from '../../../../components/modals/Clients/ModalRegisterImport';


const Clients = () => {
  const { establishment, estabSelected, accessLevel, onShowAlert, onShowQuestion } = useContext(DefaultContext);
  const [clientsEstab, setClientsEstab] = useState([])
  const [openModalRegister, setOpenModalRegister] = useState(false);
  const [openModalSaldo, setOpenModalSaldo] = useState(false);
  const [userSelected, setUserSelected] = useState(null)
  const [openImportRegister, setOpenImportRegister] = useState(false);
  const refLinkCsv = useRef(null)


  useEffect(() => {
    if (!establishment) return;
    const onSubscribe = new ClientEstabDB(establishment.id).on(setClientsEstab);
    return onSubscribe;
  }, [establishment])



  const handleOpenModalRegister = useCallback(() => {
    setUserSelected(null)
    setOpenModalRegister(true);
  }, [])

  const handleCloseModalRegister = useCallback(() => {
    setOpenModalRegister(false);
  }, [])

  const handleOpenModalEdit = (userItem) => {
    setUserSelected(userItem);
    setOpenModalRegister(true)
  }

  const handleCloseModalSaldo = useCallback(() => {
    setOpenModalSaldo(null)
    setOpenModalSaldo(false);
  }, [])

  const handleOpenModalSaldo = useCallback((userItem) => {
    setUserSelected(userItem);
    setOpenModalSaldo(true)
  }, [])

  const handleOpenModalDelete = useCallback((row) => {
    onShowQuestion({
      title: 'Exclusão de Usuário',
      message: `Você está excluindo ${row?.name}. Está ação é irreversível. Deseja continuar?`,
      onConfirm: () => {
        new ClientEstabDB(establishment.id).delete(row?.id)
          .then(() => {
            onShowAlert(PreAlert.success('Usuário deletado com sucesso'))
          })
          .catch(() => onShowAlert(PreAlert.error('Falhou ao deletar o usuário')))
      }
    })
  }, [])
  const onDownloadCsvFile = useCallback(() => {
    refLinkCsv.current.click();
  }, [refLinkCsv])

  const handleRegister = useCallback(() => {
    console.log('CLIQUEI')
    setOpenImportRegister(true)
  }, [])

  const handleCloseRegister = useCallback(() => {
    setOpenImportRegister(false)
  }, [])


  const columns = useMemo(() => ([
    {
      label: '',
      field: 'icon',
      format: row => <Icon icon="Person" size="3x" />,
      noExport: true,
    },
    {
      label: 'Nome',
      field: 'name',
    },
    {
      label: 'CPF',
      field: 'cpf',
      format: row => masks.cpf(row.cpf),
    },
    {
      label: 'Data Nasc.',
      field: 'birthDate'
    },
    {
      label: 'Email',
      field: 'email',
    },
    {
      label: 'Telefone',
      field: 'phone',
      format: row => masks.phone(row.phone),
    },
    {
      label: 'NFC',
      field: 'idCard',
    },
    // {
    //   label: 'Status',
    //   field: 'disabled',
    //   format: row => (
    //     <Button
    //       isLink
    //       color={row.active ? 'success' : 'danger'}
    //       icon='Circle'
    //       className='text-nowrap'
    //       onClick={() => handleChangeStatus(row.id, row.active, estabSelected)}
    //     >
    //       {row.active ? 'Ativo' : 'Inativo'}
    //     </Button>
    //   )
    // },
    { // aqui
      label: 'Saldo',
      field: 'balance',
      format: row => (
        <Button
          color="light"
          className='fs-6'
          shadow="sm"
          hoverShadow="sm"
          size="sm"
          onClick={() => handleOpenModalSaldo(row)}
        >
          {Money.centsToMaskMoney(row.balance)}
        </Button>

      ),
      noExport: true
    },
    {
      label: 'Ações',
      field: 'actions',
      format: row => (
        <div className='w-50 d-flex justify-content-between gap-2'>
          <Button
            color="light"
            icon="edit"
            shadow="sm"
            hoverShadow="sm"
            size="sm"
            onClick={() => handleOpenModalEdit(row)}
          />
        </div>
      ),
      noExport: true
    },
  ]), [handleOpenModalEdit, handleOpenModalDelete, handleCloseModalSaldo, estabSelected])

  return (
    <>
      <PageWrapper title={demoPages.pageLayout.subMenu.clients.text}>
        <SubHeader>
          <SubHeaderLeft>
            <Breadcrumb
              list={[
                { title: 'Cadastros', to: '/registers/clients' },
                {
                  title: 'Clientes',
                  to: '/registers/clients',
                },
              ]}
            />
          </SubHeaderLeft>
          <SubHeaderRight>
            <a hidden ref={refLinkCsv} href='https://firebasestorage.googleapis.com/v0/b/ugetpdx-prod.appspot.com/o/ExtraFiles%2Fclients-cashless.csv?alt=media&token=daf2e605-e462-4d96-abd7-0977bd958467' />
            <Button
              type='file'
              className='text-black'
              color='success'
              icon='download'
              shadow="sm"
              hoverShadow="sm"
              onClick={onDownloadCsvFile}
            >
              Download CSV
            </Button>
            <Button
              color='warning'
              icon='upload'
              shadow="sm"
              hoverShadow="sm"
              onClick={handleRegister}
            >
              Importar CSV
            </Button>


            <Button
              color='primary'
              icon='plus'
              shadow="sm"
              hoverShadow="sm"
              onClick={handleOpenModalRegister}
            >
              Cadastrar Único
            </Button>
          </SubHeaderRight>
        </SubHeader>
        <Page container='fluid'>
          <Card>
            <CardBody className='table-responsive p-5'>
              {accessLevel === ACCESS_LEVEL.ADMIN &&
                <SelectEstablishment />
              }
              <TableCustom
                rows={clientsEstab}
                columns={columns}
                keyExtractor={item => item.uid}
                fileName={'Clientes'}
              />
            </CardBody>
          </Card>
        </Page>
      </PageWrapper>

      <ModalRegisterClients
        open={openModalRegister}
        setIsOpen={setOpenModalRegister}
        setIsClose={handleCloseModalRegister}
        editData={userSelected}
      />

      <ModalRegisterImport
        open={openImportRegister}
        setIsOpen={setOpenImportRegister}
        setIsClose={handleCloseRegister}
      />
      {openModalSaldo && <ModalSaldo
        open={openModalSaldo}
        setIsOpen={setOpenModalSaldo}
        setIsClose={handleCloseModalSaldo}
        client={userSelected}
      />}
    </>
  );
};

export default Clients;
